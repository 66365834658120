import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const styles = (theme) => ({});

const reports = [
  {
    name: "Duty Status",
    url: "duty-status",
    tag: "HOS",
    detail: "Detailed activity by driver's duty status",
  },
  {
    name: "HOS Recap",
    url: "hos-recap",
    tag: "COMPLIANCE",
    detail: "Report of HOS recaps for drivers",
  },
  {
    name: "State Mileage",
    url: "state-mileage",
    tag: "COMPLIANCE",
    detail: "Report of state mileage for equipments",
  },
  // {
  //   name: "Drivers Detail",
  //   url: "drivers-detail",
  //   tag: "ASSET",
  //   detail: "Details of all drivers",
  // },
  //
  // {
  //   name: "Vehicle Detail",
  //   url: "vehicle-detail",
  //   tag: "ASSET",
  //   detail: "Details of all vehicles",
  // },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Report(props) {
  const accountBaseUrl = useSelector((state) => state.authData.accountBaseUrl);

  function handleClick(url) {
    props.history.push(`${accountBaseUrl}/reports/${url}`);
  }

  return (
    <div
      style={{
        padding: "40px 10px 40px 40px",
      }}
    >
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {reports.map((item, idx) => {
              return (
                <Grid
                  style={{
                    minHeight: 100,
                  }}
                  item
                  xs={12}
                  md={12}
                  key={`id-${idx}-${item.url}`}
                >
                  <Paper elevation={2} onClick={() => handleClick(item.url)}>
                    <div
                      style={{
                        minHeight: 100,
                        padding: 25,
                        textAlign: "left",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Typography variant="h5" component="h2">
                        {item.name}
                      </Typography>
                      <Typography variant="p" component="p">
                        {item.detail}
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(Report);
