import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { bindActionCreators } from "redux";
import _get from "lodash/get";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import { Formik, Form } from "formik";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputBox from "../../../components/InputBox";
import InputDateBox from "../../../components/InputDateBox";
import SelectBox from "../../../components/SelectBox";
import { validate } from "./validate";
import Error from "../../Error";
import Success from "../../Success";
import {
  buildDate,
  getStartOfDay,
  formatDateTime,
  formatDateTimeUtc,
  getDate,
  formatDateTimeToUtc,
} from "../../../actions/momentActions";
import { createEditRequest } from "../../../actions/logActions";
import { getDrivers } from "../../../actions/driverActions";
import {
  updateAdminLogs,
  addAdminLogs,
  citySearch,
} from "../../../actions/account/accountAdminActions";
import { getEquipments } from "../../../actions/equipmentActions";
// import { EDIT_REASONS, ALL_STATES } from "../../../constants";
import { ALL_STATES } from "../../../constants";
// import { gMapApiKey } from "../../../utils/constants";
import {
  RECORD_ORIGIN_OPTIONS,
  RECORD_STATUS_OPTIONS,
  EVENT_TYPE_OPTIONS,
  EVENT_CODES_OPTIONS,
  SUB_EVENT_TYPE_OPTIONS,
  COORD_CODE_OPTIONS,
} from "../../../constantsStatus";
import { getPayload } from "./helpers";
import { getAddress } from "../../../utils/distance";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { LABELS as _LABELS } from "../../../language";

const LABELS = _LABELS.dutyPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapperButton: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
});

export class AddDailyStatusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loading: false,
      errorMessage: "",
      successMessage: "",
      drivers: [],
      equipments: [],
      formValues: null,
    };
  }

  componentDidMount() {
    if (
      this.props.editType === "editAdmin" ||
      this.props.editType === "duplicateAdmin" ||
      this.props.editType === "addAdmin"
    ) {
      this.getCoDrivers();
      this.getEquipments();
    }
  }

  handleSubmit = async (formValues) => {
    this.setState({ isOpen: true, formValues });
  };

  finalSubmit = async () => {
    const isAdminAdd = this.props.editType === "addAdmin";

    if (isAdminAdd) {
      this.handleAddStatus();
    } else {
      this._handleSubmit();
    }
  };

  handleAddStatus = async () => {
    try {
      const timezone = this.props.timezone;
      const values = this.state.formValues;
      const latitude = values.latitude;
      const longitude = values.longitude;

      values.coordinates = [latitude, longitude];

      const _driver = this.state.drivers.find(
        (dr) => dr.id === values.driverId
      );

      if (_driver && _driver.email) {
        values.driver = _driver;
      } else {
        const selectedDriver = this.props.selectedDriver || {};
        values.driver = selectedDriver.driver || {};
      }
      values.eventTime = this.props.formatDateTimeToUtc(
        values.eventTime,
        timezone
      );
      const response = await this.props.addAdminLogs(values);
      if (response && response._id) {
        this.setState({
          successMessage: "Saved successfully.",
          loading: false,
        });
        this.props.onDone();
      } else {
        this.setState({
          errorMessage: "Something went wrong, please try after sometime.",
          loading: false,
          formValues: null,
        });
      }
    } catch (e) {
      this.setState({
        errorMessage: "Something went wrong, please try after sometime.",
        loading: false,
        formValues: null,
      });
    }
  };

  _handleSubmit = async () => {
    const timezone = this.props.timezone;
    const values = this.state.formValues;
    const selLog = this.props.selLog || {};
    const selectedDate = this.props.selectedDate;
    const selectedDriver = this.props.selectedDriver || {};
    const driver = selectedDriver.driver || {};

    if (!selLog._id) {
      const mills = moment(values.eventTime).valueOf();
      const { allDayEvents = [] } = this.props;
      const pList = allDayEvents.filter(
        (e) =>
          e.eventType === "CHANGE_DUTY_STATUS" &&
          moment(e.eventTime).valueOf() < mills
      );
      let previousStatus = null;
      if (pList.length > 0) {
        previousStatus = pList[pList.length - 1];
      }

      if (
        previousStatus != null &&
        previousStatus.recordOrigin === "AUTOMATICALLY" &&
        previousStatus.recordStatus === "ACTIVE" &&
        previousStatus.eventCode === "DRIVING" &&
        mills >= moment(previousStatus.eventTime).valueOf()
      ) {
        this.setState({
          errorMessage:
            "Check event time. You can't add status after automatic drive.",
          loading: false,
        });
        return;
      }
    }
    this.setState({
      errorMessage: "",
      successMessage: "",
      loading: true,
    });

    let response;
    const _editType = this.props.editType;
    if (_editType === "editAdmin" || _editType === "duplicateAdmin") {
      const latitude = values.latitude || 0;
      const longitude = values.longitude || 0;
      values.coordinates = [latitude, longitude];

      const _driver = this.state.drivers.find(
        (dr) => dr.id === values.driverId
      );

      if (_driver && _driver.email) {
        values.driver = _driver;
      }

      values.accountId = selLog.accountId;
      values.annotation = values.notes;

      if (_editType === "editAdmin") {
        values.id = selLog.id;
      } else {
        values.action = "DUPLICATE";
      }
      values.eventTime = this.props.formatDateTimeToUtc(
        values.eventTime,
        timezone
      );
      response = await this.props.updateAdminLogs(values);
    } else {
      const payload = getPayload(
        driver,
        values,
        selLog,
        selectedDate,
        timezone,
        this.props.formatDateTimeToUtc
      );
      response = await this.props.createEditRequest(payload);
    }

    if (response && response._id) {
      this.setState({
        successMessage: "Saved successfully.",
        loading: false,
        formValues: null,
      });
      this.props.onDone();
    } else {
      this.setState({
        errorMessage: "Something went wrong, please try after sometime.",
        loading: false,
        formValues: null,
      });
    }
  };

  getCoDrivers = () => {
    const dailyDiary = this.props.dailyDiary || [];
    let drivers = [];

    for (let i = 0; i < dailyDiary.length; i++) {
      const _d1 = dailyDiary[i].driver || {};
      const _d = dailyDiary[i].coDriver || [];
      drivers = [...drivers, ..._d, _d1];
    }

    this.setState({ drivers });
  };

  getEquipments = () => {
    const dailyDiary = this.props.dailyDiary || [];
    let drivers = [];

    for (let i = 0; i < dailyDiary.length; i++) {
      const _d1 = dailyDiary[i].driver || {};
      const _d = dailyDiary[i].coDriver || [];
      drivers = [...drivers, ..._d, _d1];
    }

    this.setState({ drivers });
  };

  getEquipments = async () => {
    try {
      const payload = {
        skip: 0,
        limit: 100,
        active: "true",
        assetType: "VEHICLE",
      };
      const equipments = await this.props.getEquipments(payload);

      this.setState({ equipments });
    } catch (e) {
      console.log(e);
    }
  };

  findAddress = async (lat, lng) => {
    try {
      const response = await this.props.citySearch({ lat, lng });

      const location = _get(response, "[0]");
      if (location) {
        const lat2 = _get(response, "[0]location.coordinates[1]");
        const lng2 = _get(response, "[0]location.coordinates[0]");
        const params = { location, lat, lng, lat2, lng2 };
        const address = getAddress(params);
        const city = location.city;
        const state = location.state_abbr;
        return { address, city, state };
      }
    } catch (e) {
      console.log(e);
      return { city: "", state: "" };
    }
  };

  render() {
    const {
      classes,
      selectedDate,
      name,
      editType,
      logIndex,
      timezone,
    } = this.props;
    const isOpen = this.state.isOpen;

    if (timezone) {
      moment.tz.setDefault(timezone);
    }
    const prevLog = this.props.prevLog || {};
    // const prevLog2 = this.props.prevLog2 || {};
    const isTimeDrBtw = this.state.isTimeDrBtw || false;
    const selLog = this.props.selLog || {};
    const selectedDriver = this.props.selectedDriver || {};
    const {
      errorMessage,
      // errorMessageDr,
      successMessage,
      loading,
    } = this.state;
    const isSmallScreen = window.innerWidth < 400;
    const isAdminEdit =
      editType === "editAdmin" || editType === "duplicateAdmin";
    const isAdminAdd = editType === "addAdmin";
    const isCertify = selLog.eventType === "CERTIFICATION";

    let initialValues = {
      eventTime: selLog.eventTime
        ? this.props.formatDateTimeUtc(selLog.eventTime, timezone)
        : null,
      // eventTime: selLog.eventTime,
      odometer: selLog.odometer ? selLog.odometer : "",
      engineHours: selLog.engineHours ? selLog.engineHours : "",
      eventCode: selLog.eventCode || "",
      location: selLog.location ? selLog.location : "",
      state: selLog.state ? selLog.state : "",
      // reason: selLog.reason ? selLog.reason : "",
      notes: selLog.annotation ? selLog.annotation : "",
    };
    if (isAdminAdd) {
      initialValues.accountId = selectedDriver.accountId;
      initialValues.driverId = _get(selectedDriver, "driver.id");
      initialValues.editType = editType;
      initialValues.coordCode = "M";
      initialValues.editType = "addAdmin";
      initialValues.eventCode = "OFFDUTY";
      initialValues.eventSubType = "status";
      initialValues.eventType = "CHANGE_DUTY_STATUS";
      initialValues.recordOrigin = "EDITED_ENTERED";
      initialValues.recordStatus = "ACTIVE";
      initialValues.latitude = 0;
      initialValues.longitude = 0;
      initialValues.accumulatedHours = 0;
      initialValues.accumulatedMiles = 0;
    }

    if (isAdminEdit) {
      initialValues.editType = editType;
      initialValues.driver = selLog.driver;
      initialValues.driverId = selLog.driverId;
      initialValues.trailer = selLog.trailer;
      initialValues.vehicle = selLog.vehicle;

      initialValues.recordOrigin = selLog.recordOrigin;
      initialValues.recordStatus = selLog.recordStatus;
      initialValues.eventType = selLog.eventType;
      initialValues.eventSubType = selLog.eventSubType;

      initialValues.sequenceId = selLog.sequenceId;
      initialValues.shippingDoc = selLog.shippingDoc;
      initialValues.accumulatedHours = selLog.accumulatedHours;
      initialValues.accumulatedMiles = selLog.accumulatedMiles;

      initialValues.coordCode = selLog.coordCode;
      initialValues.latitude = selLog.coordinates && selLog.coordinates[0];
      initialValues.longitude = selLog.coordinates && selLog.coordinates[1];
      initialValues.vehicleId = selLog.vehicle && selLog.vehicle.id;
    }

    let firstStatus = false;
    if (
      initialValues.eventTime &&
      moment.tz(initialValues.eventTime, timezone).toISOString() ===
        this.props.getStartOfDay(initialValues.eventTime).toISOString() &&
      logIndex === 0
    ) {
      firstStatus = true;
    }

    let prevAutomatic =
      prevLog.recordStatus === "ACTIVE" &&
      prevLog.eventCode === "DRIVING" &&
      prevLog.recordOrigin === "AUTOMATICALLY";

    if (isAdminEdit) {
      prevAutomatic = false;
    }

    const dateFormat =
      isAdminEdit || isAdminAdd
        ? "MM/DD/yyyy hh:mm:ss A"
        : "MM/DD/yyyy hh:mm:ss A";

    let dateDisabled = firstStatus || prevAutomatic;

    if (isAdminAdd) {
      dateDisabled = false;
    }
    const isAdmin = isAdminEdit || isAdminAdd;

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>
            {/* {`${name || "Add"} Log`}
            {isAdminAdd ? " (Admin)" : ""}   */}

            {`${name || LABELS.add} ${LABELS.log}`}
            {isAdminAdd ? LABELS.admin : ""}
          </Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <ConfirmDialog
            open={isOpen}
            label={LABELS.drActionReqMsg}
            agreeLabel={"Accept"}
            disAgreeLabel={"Reject"}
            text={`${LABELS.drConfirmReqMsg}`}
            onClose={(isTrue) => {
              if (isTrue) {
                this.finalSubmit();
              }
              this.setState({ isOpen: false });
            }}
          />
          {isTimeDrBtw && <Error message={`${LABELS.automaticDrMsg}`} />}
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue, handleChange }) => {
              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <InputDateBox
                      type="datetime-local"
                      name="eventTime"
                      label={LABELS.eventTime}
                      format={dateFormat}
                      disabled={dateDisabled}
                      timezone={timezone}
                      onChange={(e) => {
                        const _selDate = moment(e.target.value);
                        if (!isAdminEdit) {
                          const autoDrLimits = this.props.autoDrLimits || [];

                          if (autoDrLimits.length > 0) {
                            for (let i = 0; i < autoDrLimits.length; i++) {
                              const _sel = autoDrLimits[i] || {};
                              if (_selDate.isBetween(_sel.start, _sel.end)) {
                                this.setState({ isTimeDrBtw: true });
                                break;
                                return;
                              } else {
                                this.setState({ isTimeDrBtw: false });
                              }
                            }
                          }
                        }
                      }}
                    />
                    <SelectBox
                      name="eventCode"
                      label={LABELS.status}
                      disabled={values.sDisable}
                      items={EVENT_CODES_OPTIONS}
                    />
                    {!isCertify && (
                      <InputBox
                        type="number"
                        name="odometer"
                        label={LABELS.odometer}
                      />
                    )}
                    {(isAdminEdit || isAdminAdd) && (
                      <React.Fragment>
                        {!isCertify && (
                          <SelectBox
                            md={isAdmin ? 5 : 6}
                            name="coordCode"
                            label={LABELS.CoordCode}
                            items={COORD_CODE_OPTIONS}
                          />
                        )}
                        {!isCertify && (
                          <InputBox
                            md={3}
                            name="latitude"
                            label={LABELS.latitude}
                          />
                        )}
                        {!isCertify && (
                          <InputBox
                            md={3}
                            name="longitude"
                            label={LABELS.longitude}
                          />
                        )}
                      </React.Fragment>
                    )}
                    {isAdmin && (
                      <Grid item md={1} xs={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={!(values.latitude && values.longitude)}
                          size={"small"}
                          style={{ marginTop: 15 }}
                          onClick={async (e) => {
                            const lat = values.latitude;
                            const lng = values.longitude;
                            const response = await this.findAddress(lat, lng);
                            if (response && response.address) {
                              setFieldValue("state", response.state);
                              setFieldValue("location", response.address);
                            }
                          }}
                        >
                          {LABELS.find}
                        </Button>
                      </Grid>
                    )}
                    {!isCertify && (
                      <InputBox name="location" label={LABELS.Location} />
                    )}
                    {!isCertify && (
                      <SelectBox
                        name="state"
                        label={LABELS.state}
                        selLabel={"id"}
                        selLabelThree={"name"}
                        items={ALL_STATES}
                      />
                    )}
                    <InputBox name="notes" label="Note" />
                    {/*!isCertify && (
                      <SelectBox
                        md={12}
                        name="reason"
                        label={LABELS.reasonForEdit}
                        items={EDIT_REASONS}
                      />
                    )*/}
                    {(isAdminEdit || isAdminAdd) && (
                      <React.Fragment>
                        <SelectBox
                          md={3}
                          name="recordOrigin"
                          label={LABELS.recordOrigin}
                          items={RECORD_ORIGIN_OPTIONS}
                        />
                        <SelectBox
                          md={3}
                          name="recordStatus"
                          label={LABELS.recordStatus}
                          items={RECORD_STATUS_OPTIONS}
                        />
                        <SelectBox
                          md={3}
                          name="eventType"
                          label={LABELS.eventType}
                          items={EVENT_TYPE_OPTIONS}
                        />
                        <SelectBox
                          md={3}
                          name="eventSubType"
                          label={LABELS.subType}
                          items={SUB_EVENT_TYPE_OPTIONS}
                        />

                        <SelectBox
                          md={3}
                          name="driverId"
                          label={LABELS.driver}
                          selValue={"id"}
                          selLabel={"firstName"}
                          selLabelTwo={"lastName"}
                          items={this.state.drivers}
                        />
                        <InputBox
                          md={3}
                          name="trailer"
                          label={LABELS.trailer}
                        />
                        <SelectBox
                          md={3}
                          name="vehicleId"
                          label={LABELS.vehicle}
                          selValue={"id"}
                          selLabel={"name"}
                          items={this.state.equipments}
                          onChange={(e) => {
                            const _vId = e.target.value;
                            const veh = this.state.equipments.find(
                              (item) => item.id === _vId
                            );

                            if (veh && veh.id) {
                              setFieldValue("vehicle", {
                                id: veh.id,
                                name: veh.name,
                                vin: veh.vin,
                              });
                            }
                          }}
                        />

                        {!isCertify && (
                          <InputBox
                            md={3}
                            name="sequenceId"
                            label={LABELS.sequenceId}
                          />
                        )}
                        <InputBox
                          md={3}
                          name="shippingDoc"
                          label={LABELS.shippingDoc}
                        />
                        {!isCertify && (
                          <InputBox
                            md={3}
                            name="odometer"
                            label={LABELS.odometer}
                          />
                        )}
                        {!isCertify && (
                          <InputBox
                            md={3}
                            name="engineHours"
                            label={LABELS.EngineHours}
                          />
                        )}
                        {!isCertify && (
                          <InputBox
                            md={3}
                            name="accumulatedHours"
                            label={LABELS.accumulatedHours}
                          />
                        )}
                        {!isCertify && (
                          <InputBox
                            md={3}
                            name="accumulatedMiles"
                            label={LABELS.accumulatedMiles}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading || isTimeDrBtw}
                    >
                      {loading ? LABELS.saving : LABELS.save}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      {LABELS.close}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timezone: _get(state, "appData.timezone", ""),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildDate,
      getStartOfDay,
      formatDateTime,
      formatDateTimeUtc,
      getDate,
      formatDateTimeToUtc,
      createEditRequest,
      getDrivers,
      updateAdminLogs,
      addAdminLogs,
      citySearch,
      getEquipments,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AddDailyStatusModal)
);
