import axiosOrg from "axios";
import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./dashcamActionTypes";
import { gMapApiKey } from "../../utils/constants";

export const setDashCameras = (value) => ({
  type: TYPES.DASH_CAMERAS,
  value,
});

async function getAddress(lat, lng) {
  try {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&location_type=ROOFTOP&result_type=street_address&key=${gMapApiKey}`;
    const response = await axiosOrg.get(url);
    console.log("response ::: ", response.data);
    return response.data;
  } catch (e) {
    console.log(e);
  }
}
export function getDashCameras(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      const accountId = _get(getState(), "authData.profile.user.accountId");
      const customerId = _get(
        getState(),
        "authData.profile.accountProperties.dcId"
      );

      if (!customerId) {
        return null;
      }
      const response = await axios({
        url: `${BASE_URL}/manager/${accountId}/dashcam`,
        method: "get",
        params: {
          ...params,
          customerId,
        },
      });
      const _response = _get(response, "data");
      return _response;
    } catch (error) {
      const messageMsg = _get(error, "response.data.errors[0].message");
      return {
        error: true,
        messageMsg,
      };
    }
  };
}

export function getDcImages(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      const accountId = _get(getState(), "authData.profile.user.accountId");

      const response = await axios({
        url: `${BASE_URL}/manager/${accountId}/dashcam/images`,
        method: "get",
        params,
      });
      const _response = _get(response, "data");
      return _response;
    } catch (error) {
      const messageMsg = _get(error, "response.data.errors[0].message");
      return {
        error: true,
        messageMsg,
      };
    }
  };
}

export function getDcLiveview(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      const accountId = _get(getState(), "authData.profile.user.accountId");

      const response = await axios({
        url: `${BASE_URL}/manager/${accountId}/dashcam/liveview`,
        method: "get",
        params,
      });
      const _response = _get(response, "data");
      return _response;
    } catch (error) {
      const messageMsg = _get(error, "response.data.errors[0].message");
      return {
        error: true,
        messageMsg,
      };
    }
  };
}

export function getDcDetail(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      const accountId = _get(getState(), "authData.profile.user.accountId");

      const response = await axios({
        url: `${BASE_URL}/manager/${accountId}/dashcam/detail`,
        method: "get",
        params,
      });
      const _response = _get(response, "data");

      if (_response && _response.Latitude && _response.Longitude) {
        _response.locInfo = await getAddress(
          _response.Latitude,
          _response.Longitude
        );
      }
      return _response;
    } catch (error) {
      const messageMsg = _get(error, "response.data.errors[0].message");
      return {
        error: true,
        messageMsg,
      };
    }
  };
}
