import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import validate from "./validate";
import Grid from "@mui/material/Grid";
import SelectBox from "../../../../components/SelectBox";
import CheckBox from "../../../../components/CheckBoxNew";
import Error from "../../../Error";
import Success from "../../../Success";
import {
  createNotificationsSetup,
  updateNotificationsSetup,
} from "../../../../actions/notificationsActions";
import { API_STATUS, NOTIFICATION_SETUP_TYPES } from "../../../../constants";
import { LABELS as _LABELS } from "../../../../language";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  buttons: {
    marginTop: 30,
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class ApiForm extends React.Component {
  state = {
    errorMessage: "",
    successMessage: "",
  };

  handleSubmit = async (values) => {
    this.setState({ loading: true });
    let response;
    if (this.props.isEdit) {
      const id = _get(this.props, "selected._id", "");
      let editPayload = {
        id,
        active: values.active,
        type: values.type,
        allDrivers: true,
        allVehicles: true,
        isPush: values.isPush,
        isEmail: values.isEmail,
      };

      response = await this.props.updateNotificationsSetup(editPayload);
    } else {
      let payload = {
        type: values.type,
        active: values.active,
        allDrivers: true,
        allVehicles: true,
        isPush: values.isPush,
        isEmail: values.isEmail,
      };
      response = await this.props.createNotificationsSetup(payload);
    }

    if (response && response._id) {
      this.setState({ successMessage: LABELS.successMessage_Api_Key });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.status === 400) {
      this.setState({ errorMessage: response.message });
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message });
    } else {
      if (typeof response !== String) {
        response = LABELS.response;
      }
      this.setState({ errorMessage: response });
    }
    this.setState({ loading: false });
  };

  render() {
    const { classes, selected = {} } = this.props;
    const { errorMessage, successMessage, loading } = this.state;
    let initialValues = {
      type: "OVERSPEED",
      active: true,
      isPush: true,
      isEmail: false,
      ...selected,
    };

    return (
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
      >
        {({ values = {}, handleSubmit, errors }) => {
          return (
            <Form style={{ padding: "10px 10px" }}>
              <Grid container spacing={3}>
                <SelectBox
                  md={4}
                  name="type"
                  label={LABELS.type}
                  items={NOTIFICATION_SETUP_TYPES}
                />
                <SelectBox
                  md={4}
                  name="active"
                  label={LABELS.status}
                  items={API_STATUS}
                />
                <CheckBox paddingTop={45} md={3} name="isPush" label={" Push notification"} />
                {/*<CheckBox
                  paddingTop={45}
                  md={2}
                  sm={6}
                  xs={6}
                  name="isEmail"
                  label={"Email"}
                />*/}
              </Grid>
              {successMessage && <Success message={successMessage} />}
              {errorMessage && <Error message={errorMessage} />}
              <div className={classes.buttons}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  size="small"
                  style={{ minWidth: 150 }}
                >
                  {/* {loading ? "Saving..." : "SAVE"} */}
                  {loading ? `${LABELS.saving}` : `${LABELS.save}`}
                </Button>
                <br />
                <Button
                  size="small"
                  variant="link"
                  onClick={this.props.handleClose}
                >
                  {LABELS.close}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    isAdmin: !!_get(state, "authData.profile.adminId"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createNotificationsSetup,
      updateNotificationsSetup,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiForm))
);
