import * as React from "react";
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ExploreIcon from "@mui/icons-material/Explore";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.settingPage;

const styles = (theme) => ({});

function IftaDetailTable(props) {
  const classes = props.classes;
  const allStatus = props.allStatus;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>{LABELS.location}</TableCell>
            <TableCell align="left">{LABELS.coordinates}</TableCell>
            <TableCell align="left">{LABELS.event}</TableCell>
            <TableCell align="left">{LABELS.created}</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allStatus.map((item, index) => {
            const city = item.city ? `${item.city}, ` : "";
            const state = item.state || "";
            const coords = item.coordinates || [];
            const date = item.created ? props.formatDateTime(item.created) : "";
            return (
              <TableRow key={item._id}>
                <TableCell component="th" scope="row">
                  {`${city} ${state}`}
                </TableCell>
                <TableCell align="left">{`${coords[0]}, ${coords[1]}`}</TableCell>
                <TableCell align="left">{item.eventCode}</TableCell>
                <TableCell align="left">{date}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="Map"
                    onClick={() => props.viewOnMap(index)}
                  >
                    <ExploreIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default withStyles(styles)(IftaDetailTable);
