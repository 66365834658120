import moment from "moment-timezone";
import _get from "lodash/get";
import { getAllDayEvents } from "../DailyStatus/helpers";

moment.createFromInputFallback = function (config) {
  config._d = new Date(config._i);
};

export function formatLogs({ to, from, allLogs, timezone }) {
  const result = {};

  const startDate = moment.tz(from, timezone);
  const endDate = moment.tz(to, timezone);
  const dates = [];

  let currentDate = startDate.clone();
  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.format("YYYY/MM/DD"));
    currentDate.add(1, "days"); // Increment by one day
  }

  const _lastStatus = allLogs.driverLastLogs || {};

  let __allLogs = allLogs.driverLogs || [];

  const lastStatus = {
    ..._lastStatus,
    isFirst: true,
    tag: "FIRST_ONE",
    eventTime: moment.tz(dates[0], timezone).startOf("day"),
  };

  // __allLogs.unshift(lastStatus);

  const _allLogs = __allLogs.filter(
    (item) =>
      item.eventType === "CHANGE_DUTY_STATUS" && item.recordStatus === "ACTIVE"
  );

  for (let i = 0; i < _allLogs.length; i++) {
    const sel = _allLogs[i] || {};

    const keyDate = moment.tz(sel.eventTime, timezone).format("YYYY/MM/DD");

    result[keyDate] = result[keyDate] || {};
    result[keyDate].date = sel.eventTime;
    result[keyDate].logs = result[keyDate].logs || [];
    result[keyDate].logs.push(sel);
  }

  let formatLogs = [];
  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];
    let logs = (result[date] && result[date].logs) || [];
    if (i === 0) {
      logs.unshift(lastStatus);
    }
    if (formatLogs && formatLogs[i - 1] && formatLogs[i - 1].logs) {
      const _curLogs = formatLogs[i - 1].logs || [];
      const _curLog = _curLogs[_curLogs.length - 1];

      const __curLog = {
        ..._curLog,
        tag: "FIRST",
        eventTime: moment.tz(`${date}`, timezone).startOf("day"), //.add(1, "hours"),
      };

      logs.unshift(__curLog);

      if (logs.length === 1) {
        const __lastLog = {
          ..._curLog,
          tag: "ALL_LAST",
          isAllLast: true,
          eventTime: moment.tz(`${date}`, timezone).endOf("day"), //.add(1, "hours"),
        };
        logs.push(__lastLog);
      }
    // } else {
      // const __lastLog = {
      //   ...logs[i],
      //   tag: "FIRST_LAST",
      //   eventTime: moment.tz(`${date}`, timezone).endOf("day"), //.add(1, "hours"),
      // };
      // logs.push(__lastLog);
    }

    formatLogs.push({
      date,
      logs,
    });
  }

  allLogs.formatLogs = formatLogs.map((item, idx) => {
    let lastEvent;
    for (let i = 0; i < _allLogs.length; i++) {
      const _sel = _allLogs[i] || {};
      const keyDate = moment.tz(_sel.eventTime, timezone).format("YYYY/MM/DD");

      if (keyDate === item.date) {
        break;
      }
      lastEvent = _sel;
    }

    let _logs = item.logs || [];

    item.logs = getAllDayEvents(timezone, item.date, _logs);
    return item;
  });

  return allLogs;
}
