import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./accountActionTypes";

export const setAccounts = (value) => ({
  type: TYPES.ACCOUNTS,
  value,
});

export const selectedAccount = (value) => ({
  type: TYPES.SELECTED_ACCOUNT,
  value,
});

export const selectedAccountData = (value) => ({
  type: TYPES.SELECTED_ACCOUNT_DATA,
  value,
});

export const accountsCreateBusy = (value) => ({
  type: TYPES.ACCOUNTS_CREATE_BUSY,
  value,
});

export const accountsBusy = (value) => ({
  type: TYPES.ACCOUNTS_BUSY,
  value,
});

export const accountsAuthBusy = (value) => ({
  type: TYPES.ACCOUNTS_AUTH_BUSY,
  value,
});

export function createAccount(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    dispatch(accountsCreateBusy(true));
    return axios({
      url: `${BASE_URL}/admin/accounts`,
      method: "post",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(accountsCreateBusy(false));
        return response;
      });
  };
}

export function updateAccount(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/accounts`,
      method: "put",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getAccountsCount(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    return axios({
      url: `${BASE_URL}/admin/accounts/count`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setAccounts(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(accountsBusy(false));
        return response;
      });
  };
}

export function getAccounts(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    return axios({
      url: `${BASE_URL}/admin/accounts`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setAccounts(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(accountsBusy(false));
        return response;
      });
  };
}

export function getAccountDetails(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    return axios({
      url: `${BASE_URL}/admin/accounts/${params.accountId}`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(selectedAccountData(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getAccountProperties(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    return axios({
      url: `${BASE_URL}/admin/accounts/${params.accountId}/properties`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function updateAccountProperties(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    return axios({
      url: `${BASE_URL}/admin/accounts/${params.accountId}/properties`,
      method: "put",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getAccountAuth(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    dispatch(accountsAuthBusy(true));
    return axios({
      url: `${BASE_URL}/admin/authorize`,
      method: "put",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(accountsAuthBusy(false));
        return response;
      });
  };
}

export function updateUserAccount(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/accounts/${params.account}/user/${params.id}`,
      method: "put",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function updateAdminLogs(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const id = params.id;
    return axios({
      url: `${BASE_URL}/admin/accounts/${params.accountId}/status/${id}`,
      method: "put",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function addAdminLogs(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const id = params.id;
    return axios({
      url: `${BASE_URL}/admin/accounts/${params.accountId}/status`,
      method: "post",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function deleteAdminLogs(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const id = params.id;
    const accountId = params.account || params.accountId;
    return axios({
      url: `${BASE_URL}/admin/accounts/${accountId}/status/${id}`,
      method: "delete",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getStatusAuditAdmin(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/accounts/${params.accountId}/statusaudit`,
      method: "get",
      params: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function undoAdminLog(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const statusId = params.statusId;
    return axios({
      url: `${BASE_URL}/admin/accounts/${params.accountId}/undostatus/${statusId}`,
      method: "put",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function citySearch(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const id = params.id;
    return axios({
      url: `${BASE_URL}/admin/citysearch`,
      method: "get",
      params: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function deleteAdminCalcus(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const id = params.id || params._id;
    return axios({
      url: `${BASE_URL}/admin/accounts/${params.account}/calcs/${id}`,
      method: "delete",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}
