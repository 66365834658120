import _get from "lodash/get";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDueInvoices } from "../../actions/reseller/InvoiceActions";
import { formatTimeSpan, getTimeSpan } from "../../utils/momentHelpers";

import {
  isBluehorse,
  isRadical,
  isApex,
  isRoadwise,
} from "../../configureStore";

const styles = (theme) => ({});

function Detail(props) {
  const [isPastDue, setPastDue] = useState(false);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    if (isBluehorse || isRadical || isApex || isRoadwise) {
      _getDueInvoices();
    }

    return () => {};
  }, []);

  const _getDueInvoices = async () => {
    const response = await props.getDueInvoices();

    let _isPastDue = false;
    let _invoices = [];

    for (var i = 0; i < response.length; i++) {
      const item = response[i] || {};

      const dueDate = item.due_date || {};
      if (item.due_date < getTimeSpan()) {
        _isPastDue = true;
      }
      const lineItem = _get(item, "lines.data[0].description", "");

      if (lineItem.includes("IOS/Android vehicles")) {
        _invoices.push({
          id: item.id,
          number: item.number,
          isDue: item.due_date < getTimeSpan(),
          dueDate: formatTimeSpan(dueDate),
          subtotal: item.subtotal,
          tax: item.tax,
          total: item.total,
        });
      }
    }

    setPastDue(_isPastDue);
    setInvoices(_invoices);
  };

  if (invoices.length === 0) {
    return null;
  }

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Alert severity="error" style={{ margin: 10 }}>
        <strong>⚠️ Reminder: Invoice Due Soon</strong>
        {invoices.map((item) => (
          <div key={item.id}>
            Your invoice <strong>{item.number}</strong> is due on{" "}
            <strong>{item.dueDate}</strong>. Please complete the payment before
            due date to avoid service interruption.
          </div>
        ))}
      </Alert>
    </Grid>
  );
}

Detail.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getDueInvoices }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
