import React from "react";
import _get from "lodash/get";
import { Alert } from "@mui/lab";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formatDate, formatTime } from "../../actions/momentActions";
import {
  getEqpLogs,
  getIftaLogs,
  updateStateMileage,
} from "../../actions/stateMileageActions";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { getProfile } from "../../actions/auth/authActions";
import CircularProgress from "@mui/material/CircularProgress";
import SearchBar from "../../components/SearchBar";
import IftaDetailTable from "./IftaDetail/IftaDetailTable";
import IftaDetailMap from "./IftaDetail/IftaDetailMap";
import { gMapApiKey } from "../../utils/constants";
import { formatDateTime } from "../../actions/momentActions";

class StateMileage extends React.Component {
  state = {
    limit: 10,
    page: 0,
    index: 0,
    loading: false,
    search: false,
    allStatus: [],
  };

  getEqpLogs = async () => {
    const { vehicleId, start, end, stateId } = this.state;
    if (!vehicleId) {
      this.setState({
        errorMessage: "Select vehicle",
        loading: false,
      });
    } else {
      try {
        this.setState({ loading: true, errorMessage: "", allRoutes: [] });

        const vehicle = this.props.equipments.filter(
          (item) => item.id === vehicleId
        );
        this.setState({ vehicle });
        const iftaLogs = await this.props.getIftaLogs({
          vehicleId: vehicleId,
          from: start,
          to: end,
        });

        const _statemiles = iftaLogs.statemiles || [];

        const allStatus = _statemiles.filter((item) => {
          if (stateId && item.state !== stateId) {
            return false;
          }
          return (
            Array.isArray(item.coordinates) &&
            item.coordinates.length === 2 &&
            item.coordinates[0] !== 0 &&
            item.coordinates[1] !== 0
          );
        });

        if (allStatus && Array.isArray(allStatus) && allStatus.length > 1) {
          this.setState({
            allStatus,
            loading: false,
          });
        } else {
          this.setState({
            errorMessage: "No records are found",
            loading: false,
          });
        }
      } catch (e) {
        this.setState({ loading: false });
      }
    }
  };

  onSearch = ({ vehicleId, vehicle, start, end, reportOn, stateId }) => {
    this.setState(
      { vehicleId, vehicle, start, end, reportOn, stateId },
      this.getEqpLogs
    );
  };

  viewOnMap = (index) => {
    this.setState({ index, isMap: true });
  };

  onDownload = () => {
    const stateId = this.state.stateId;
    const vehicleName = _get(this, "state.vehicle[0].name");
    let fileName = `report-${vehicleName}`;

    if (stateId) {
      fileName = `report-${vehicleName}-${stateId}`;
    }
    const _data = this.state.allStatus;
    let data = [
      {
        vehicle: "Vehicle",
        date: "Date",
        status: "Status",
        odometer: "Odometer",
        latitide: "Latitide",
        longitude: "Longitude",
        location: "Location",
      },
    ];
    for (var i = 0; i < _data.length; i++) {
      const item = _data[i] || {};
      const date = item.created ? this.props.formatDateTime(item.created) : "";
      const odometerMiles = (item.odometer * 0.621371).toFixed(3);
      const coordinates = item.coordinates || [];
      data.push({
        vehicle: vehicleName,
        date: date,
        status: item.eventCode,
        odometer: odometerMiles,
        latitide: coordinates[0],
        longitude: coordinates[1],
        location: `${item.city} ${item.state}`,
      });
    }

    const csvData = new Blob([this.convertToCSV(data)], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  convertToCSV = (objArray) => {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  };

  render() {
    const { index, loading, isMap, errorMessage } = this.state;

    return (
      <>
        <SearchBar
          options={{
            isEqp: true,
            isState: true,
            isStartDate: true,
            isEndDate: true,
            isTime: false,
            isTimeperiod: true,
            isReport: true,
            isDownload: true,
            isDownloadIcon: true,
          }}
          loading={loading}
          onSearch={this.onSearch}
          stateMil={true}
          onDownload={this.onDownload}
        />

        {errorMessage && (
          <div style={{ margin: "20px" }}>
            <Alert severity="warning">{errorMessage}</Alert>
          </div>
        )}

        <ButtonGroup
          variant="text"
          color="primary"
          aria-label="text primary button group"
          style={{ marginLeft: 10 }}
        >
          <Button onClick={() => this.setState({ index, isMap: false })}>
            TABLE
          </Button>
          <Button onClick={() => this.setState({ index, isMap: true })}>
            MAP
          </Button>
        </ButtonGroup>

        <>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {isMap ? (
                <IftaDetailMap
                  allStatus={this.state.allStatus}
                  selIndex={index}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${gMapApiKey}`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div
                      style={{
                        height: "calc(100vh - 175px)",
                        display: "block",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      className="mapContainer"
                    />
                  }
                />
              ) : (
                <IftaDetailTable
                  allStatus={this.state.allStatus}
                  formatDateTime={this.props.formatDateTime}
                  viewOnMap={this.viewOnMap}
                />
              )}
            </>
          )}
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    equipments: _get(state, "equipmentData.equipments", []),
    adminKey: _get(state, "authData.profile.adminId"),
    carrier: _get(state, "authData.profile.account.carrier.name", ""),
    iftaApiKey: _get(state, "appData.IFTA_KEY", ""),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getIftaLogs,
      formatDate,
      formatTime,
      getEqpLogs,
      updateStateMileage,
      getProfile,
      formatDateTime,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(StateMileage);
