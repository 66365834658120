/* eslint-disable no-undef */
import _get from "lodash/get";
import { React, useState, useEffect, useCallback, useRef } from "react";
import {
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import VideocamIcon from "@mui/icons-material/Videocam";
import Button from "@mui/material/Button";
import { GoogleMap, } from "@react-google-maps/api";
import { formatPaths, formatStops, startLatLng, carImage } from "./helpers";
import { Card, IconButton, Slider } from "@mui/material";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SpeedIcon from '@mui/icons-material/Speed';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import distanceimage from "../../../src/utils/icons/speedometer.png"
import odometerIcon from "../../../src/utils/icons/odometers.png"
import { useGoogleMaps } from "../../configs/GoogleMapsContext";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const camIconStyle = {
  verticalAlign: "bottom",
  fontSize: "18px !important",
  marginRight: 0,
  width: "0.2rem",
  height: "0.2rem",
  cursor: "pointer",
};

function getIntials(item) {
  const driver = item.driver || {};
  const fName = driver.firstName
    ? driver.firstName.slice(0, 1).toUpperCase()
    : "";
  const lName = driver.lastName
    ? driver.lastName.slice(0, 1).toUpperCase()
    : "";

  return fName + lName;
}

function RenderMarker(props) {
  const item = props.item || {};
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.dId) {
      if (props.dId === item.uqId) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  });

  const address = _get(props, "item.data.address.line1");
  const _time = _get(props, "item.data.time");
  const time = `${props.formatDate(_time)} ${props.formatTime(_time)}`;

  return (
    <Marker
      position={{ lat: props.lat, lng: props.lng }}
      prerender={false}
      labelAnchor={new window.google.maps.Point(0, 0)}
      labelStyle={{
        backgroundColor: "grey",
        color: "white",
        fontSize: "10px",
        padding: "1px",
      }}
      onClick={() => {
        if (props.dId && props.onInfoWindowClose) {
          props.onInfoWindowClose(item.driverId);
        }
        setIsOpen(true);
        if (props.onMarkerSelect) {
          props.onMarkerSelect(item);
        }
      }}
      label={`${props.index + 1}`}
    >
      {isOpen ? (
        <InfoWindow
          onCloseClick={() => {
            setIsOpen(false);
            props.onInfoWindowClose();
            props.onMarkerReset();
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                textAlign: "center",
                padding: "5px 2px 0px 2px",
                marginTop: "0px",
                fontSize: "12px",
                color: "#333333",
                textTransform: "uppercase",
                cursor: "default",
                width: 200,
              }}
            >
              <p style={{ margin: "0", marginBottom: "1px", fontWeight: 600 }}>
                {`${item?.id}`}
              </p>
              <p style={{ margin: "0", marginBottom: "1px", fontWeight: 400 }}>
                {`${address}`}
              </p>
              <p style={{ margin: "0", marginBottom: "1px", fontWeight: 200 }}>
                {`${time}`}
              </p>
            </div>
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
}

function ReactMap(props) {
  const defaultCenter = {
    lat: 34.06441730765348,
    lng: -118.27857936594327,
  };

  const icon = {
    url: carImage,
    scaledSize: new window.google.maps.Size(50, 40),
    anchor: new window.google.maps.Point(25, 20),
    scale: 1,
  };

  const locations = props.locations;
  const centerLocation = locations && locations[0];
  const { isLoaded } = useGoogleMaps();
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(defaultCenter);
  const [isCarInfoWindowOpen, setIsCarInfoWindowOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState(null);
  const [updateCount, setUpdateCount] = useState(0);
  const [progress, setProgress] = useState(null);
  const [paths, setPaths] = useState([]);
  const [pausedTime, setPausedTime] = useState(null);
  const [totalPausedTime, setTotalPausedTime] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0); // New state for elapsed time
  const [totalDuration, setTotalDuration] = useState(0);

  const intervalRef = useRef(null);
  const initialDateRef = useRef(null);
  const isSimulationActiveRef = useRef(false);

  const velocity = 270;
  

  const onLoad = useCallback((map) => {
    if (centerLocation && centerLocation.coordinates) {
      const _center = {
        lat: parseFloat(centerLocation.coordinates[0]),
        lng: parseFloat(centerLocation.coordinates[1]),
      };
      const bounds = new window.google.maps.LatLngBounds(_center);
      locations?.forEach((item) => {
        if (item && item.coordinates) {
          bounds.extend({
            lat: parseFloat(item.coordinates[0]),
            lng: parseFloat(item.coordinates[1]),
          });
        }
      });
      map.fitBounds(bounds);
     
    }
    setMap(map);
  }, [centerLocation, locations]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (centerLocation && centerLocation.coordinates) {
      const _lat = parseFloat(centerLocation.coordinates[0]);
      const _lng = parseFloat(centerLocation.coordinates[1]);
      setCenter({ lat: _lat, lng: _lng });
    }
  }, [centerLocation]);


  const calculatePath = useCallback(() => {
    let initialPaths = formatPaths(locations);
    let totalPathDistance = 0;

    const calculatedPaths = initialPaths.map((coordinates, i, array) => {
      if (i === 0) {
        return { ...coordinates, distance: 0, totalDistance: 0 };
      }

      const { lat: lat1, lng: lng1 } = coordinates;
      const { lat: lat2, lng: lng2 } = array[i - 1];

      const latLong1 = new window.google.maps.LatLng(lat1, lng1);
      const latLong2 = new window.google.maps.LatLng(lat2, lng2);

      const segmentDistance = window.google.maps.geometry.spherical.computeDistanceBetween(
        latLong2,
        latLong1
      );

      totalPathDistance += segmentDistance;

      const heading = window.google.maps.geometry.spherical.computeHeading(
        latLong2,
        latLong1
      );

      return {
        ...coordinates,
        distance: totalPathDistance,
        heading,
        segmentDistance,
        segmentStart: totalPathDistance - segmentDistance
      };
    });

    setPaths(calculatedPaths);
  }, [locations]);

  const getDistance = () => {
    if (!initialDateRef.current || !paths.length) return 0;

    const currentTime = new Date();
    const elapsedTimeMs = currentTime - initialDateRef.current - totalPausedTime;
    const elapsedTimeSec = elapsedTimeMs / 1000;
    setElapsedTime(elapsedTimeSec);
    // Calculate the actual distance covered based on velocity
    const distanceCovered = elapsedTimeSec * velocity;
    // Get total path distance
    const totalDistance = paths[paths.length - 1]?.distance || 0;

    // Ensure we don't exceed the total distance
    return Math.min(distanceCovered, totalDistance);
  };

  useEffect(() => {
    if (paths.length > 0) {
      const totalDistance = paths[paths.length - 1].distance || 0; // Total distance in meters
      const durationInSeconds = totalDistance / velocity; // Calculate total duration in seconds
      setTotalDuration(durationInSeconds); // Update total duration
    }
  }, [paths]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  const moveObject = useCallback(() => {
    if (!isSimulationActiveRef.current || !paths.length) return;

    const distance = getDistance();
    if (!distance) return;

    // Check if we've reached the end of the path first
    const lastPathPoint = paths[paths.length - 1];
    if (distance >= lastPathPoint.distance) {
      setProgress(paths);
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      isSimulationActiveRef.current = false;
      return;
    }

    // Find the current segment
    const currentSegmentIndex = paths.findIndex(point => point.distance > distance) - 1;
    const nextSegmentIndex = currentSegmentIndex + 1;
    // Safety check for indices
    if (currentSegmentIndex < 0 || nextSegmentIndex >= paths.length) {
      return;
    }
    // Get current and next points
    const currentPoint = paths[currentSegmentIndex];
    const nextPoint = paths[nextSegmentIndex];
    // Calculate progress within current segment
    const segmentStart = currentPoint.segmentStart || 0;
    const segmentDistance = nextPoint.distance - segmentStart;
    const segmentProgress = (distance - segmentStart) / segmentDistance;
    const clampedProgress = Math.max(0, Math.min(1, segmentProgress));

    // Interpolate position
    const currentLatLng = new window.google.maps.LatLng(
      currentPoint.lat,
      currentPoint.lng
    );
    const nextLatLng = new window.google.maps.LatLng(
      nextPoint.lat,
      nextPoint.lng
    );

    const position = window.google.maps.geometry.spherical.interpolate(
      currentLatLng,
      nextLatLng,
      clampedProgress
    );

    // Calculate heading
    const heading = window.google.maps.geometry.spherical.computeHeading(
      currentLatLng,
      nextLatLng
    );

    // Update marker rotation
    const marker = document.querySelector(`[src="${carImage}"]`);
    if (marker) {
      marker.style.transform = `rotate(${heading - 90}deg)`;
      marker.style.transition = 'transform 0.1s ease-out';
    }

    // Calculate distance from start
    const startPoint = new window.google.maps.LatLng(paths[0].lat, paths[0].lng);
    const distanceCovered = window.google.maps.geometry.spherical.computeDistanceBetween(
      startPoint,
      position
    ) / 1609.34;

    // Update progress path
    const progressPath = [
      ...paths.slice(0, currentSegmentIndex + 1),
      {
        lat: position.lat(),
        lng: position.lng(),
        distance,
        heading,
      }
    ];

    setProgress(progressPath);
    setCurrentPath({
      lat: position.lat(),
      lng: position.lng(),
      speed: nextPoint.speed || currentPoint.speed,
      distanceCovered,
      odometerMiles: nextPoint.odometerMiles || currentPoint.odometerMiles,
      address: nextPoint.address || currentPoint.address,
      time: nextPoint.time || currentPoint.time,
      heading
    });
  }, [paths]);


  const startSimulation = useCallback(() => {
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setProgress(null);
    setPausedTime(null);
    setTotalPausedTime(0);
    initialDateRef.current = new Date();
    isSimulationActiveRef.current = true;
    intervalRef.current = window.setInterval(moveObject, 100);
  }, [moveObject]);


  const pauseSimulation = useCallback(() => {
    if (!isSimulationActiveRef.current) {
      // Resume simulation
      if (pausedTime) {
        const pauseDuration = new Date() - pausedTime;
        setTotalPausedTime(prevTotal => prevTotal + pauseDuration);
      }
      isSimulationActiveRef.current = true;
      intervalRef.current = window.setInterval(moveObject, 100);
    } else {
      // Pause simulation
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      // setPausedTime(new Date());
      isSimulationActiveRef.current = false;
    }
  }, [moveObject, pausedTime]);

  const stopSimulation = useCallback(() => {
    if (intervalRef.current) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    isSimulationActiveRef.current = false;
    // Reset progress and slider to initial states
    setProgress(null);
    setSliderValue(0); // Reset slider to the start position
    // Reset the initial date reference and paused time
    initialDateRef.current = null;
    setPausedTime(null);
    setTotalPausedTime(0);
    setElapsedTime(0);
    // Reset the current path to the initial state (if paths exist)
    if (paths && paths.length > 0) {
      setCurrentPath({
        lat: paths[0]?.lat || 0,
        lng: paths[0]?.lng || 0,
        speed: paths[0]?.speed || 0,
        distanceCovered: 0,
        odometerMiles: paths[0]?.odometerMiles || 0,
        address: paths[0]?.address || {},
        time: paths[0]?.time || "",
      });
    }
  }, [paths]);


  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      isSimulationActiveRef.current = false;
    };
  }, []);

  useEffect(() => {
    calculatePath();
  }, [calculatePath]);

  const stops = formatStops(locations);
  const centers = paths.length > 0 ? parseInt(paths.length / 2) : 0;
  const centerPathLat = paths[centers]?.lat || defaultCenter.lat;
  const centerpathLng = paths[centers]?.lng || defaultCenter.lng;

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);

    // Calculate the corresponding distance
    const totalDistance = paths[paths.length - 1]?.distance || 0;
    const targetDistance = (newValue / 100) * totalDistance;

    // Recalculate elapsed time based on slider position
    const recalculatedElapsedTime = targetDistance / velocity; // in seconds
    setElapsedTime(recalculatedElapsedTime);
    // Update the initial date reference
    if (initialDateRef.current) {
      const currentTime = new Date();
      const newInitialTime = new Date(currentTime - recalculatedElapsedTime * 1000);
      initialDateRef.current = newInitialTime;
    }
    // Find the current position on the path
    const currentPointIndex = paths.findIndex((point) => point.distance >= targetDistance);
    if (currentPointIndex > 0) {
      const previousPoint = paths[currentPointIndex - 1];
      const nextPoint = paths[currentPointIndex];

      const segmentDistance = nextPoint.distance - previousPoint.distance;
      const segmentRatio = (targetDistance - previousPoint.distance) / segmentDistance;

      // Interpolate the position between the two points
      const interpolatedPosition = window.google.maps.geometry.spherical.interpolate(
        new window.google.maps.LatLng(previousPoint.lat, previousPoint.lng),
        new window.google.maps.LatLng(nextPoint.lat, nextPoint.lng),
        segmentRatio
      );
      // Calculate the correct angle for the vehicle
      const point1LatLng = new window.google.maps.LatLng(previousPoint.lat, previousPoint.lng);
      const point2LatLng = new window.google.maps.LatLng(nextPoint.lat, nextPoint.lng);
      const angle = window.google.maps.geometry.spherical.computeHeading(
        point1LatLng,
        point2LatLng
      );
      const actualAngle = angle - 90;
      // Update the vehicle rotation
      const marker = document.querySelector(`[src="${carImage}"]`);
      if (marker) {
        marker.style.transform = `rotate(${actualAngle}deg)`;
      }
      setProgress(paths.slice(0, currentPointIndex).concat(interpolatedPosition));
      setCurrentPath({
        lat: interpolatedPosition.lat(),
        lng: interpolatedPosition.lng(),
        speed: nextPoint.speed,
        distanceCovered: targetDistance / 1609.34, // Convert meters to miles
        odometerMiles: nextPoint.odometerMiles || 0,
        address: nextPoint.address || {},
        time: nextPoint.time || "",
      });
    }
  };

  useEffect(() => {
    if (isSimulationActiveRef.current) {
      const totalDistance = paths[paths.length - 1]?.distance || 0;
      const coveredDistance = getDistance();
      const sliderValue = Math.min((coveredDistance / totalDistance) * 100, 100);

      setSliderValue(sliderValue);
    }
  }, [progress, paths]);

  const handleMarkerSelect = useCallback((item) => {
    // console.log("Selected marker:", item);
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    console.log("Info window closed");
  }, []);

  const handleMarkerReset = useCallback(() => {
    // console.log("Marker reset");
  }, []);


  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            height: "calc(100vh - 175px)",
            display: "block",
            flexDirection: "column",
            width: "100%",
          }}
          zoom={6}
          onLoad={onLoad}
          onUnmount={onUnmount}
          defaultCenter={{ lat: centerPathLat, lng: centerpathLng }}
          options={{
            gestureHandling: "greedy", 
            zoomControl: true,
            maxZoom: 16,
          }}
          

        >
          <Polyline
            path={paths}
            options={{
              strokeColor: "#0088FF",
              strokeWeight: 6,
              strokeOpacity: 1.0,
              defaultVisible: true,
            }}

          />
          {stops?.data &&
            stops.data.map((stop, idx) => (
              <RenderMarker
                key={`id-${idx}`}
                lat={stop.lat}
                lng={stop.lng}
                driver={{}}
                index={idx}
                item={stop}
                formatDate={props.formatDate}
                formatTime={props.formatTime}
                onMarkerSelect={handleMarkerSelect}
                onInfoWindowClose={handleInfoWindowClose}
                onMarkerReset={handleMarkerReset}
              />
            ))}

          {progress && (
            <>
              <Polyline
                path={progress}
                options={{ strokeColor: "orange", strokeOpacity: "0" }} />

              {currentPath && (
                <Marker
                  icon={icon}
                  position={progress[progress.length - 1]}
                  onClick={() => setIsCarInfoWindowOpen(true)}
                >
                  {isCarInfoWindowOpen && (
                    <InfoWindow
                      position={currentPath}
                      onCloseClick={() => setIsCarInfoWindowOpen(false)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '6px',
                          padding: '10px',
                          fontFamily: 'Arial, sans-serif',
                          color: '#333',
                          marginTop:"-12px",                         
                        }}
                      >
                        <h4 style={{ margin: '0', textAlign: 'center' ,fontSize:"15px",}}>Vehicle Information</h4>
                        {/* Vehicle Details Container */}
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '15px',
                            alignItems: 'center',
                          }}
                        >
                          {/* Speed */}
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <SpeedIcon style={{ fontSize: '25px', verticalAlign: 'middle' }} />
                            <span style={{ lineHeight: '30px' }}>
                              {currentPath.speed} mph
                            </span>
                          </div>

                          {/* Time */}
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <CalendarMonthIcon style={{ fontSize: '25px', verticalAlign: 'middle' }} />
                            <span style={{ lineHeight: '30px' }}>
                              {currentPath.time
                                ? props.formatDate(currentPath.time) + ' ' + props.formatTime(currentPath.time)
                                : 'N/A'}
                            </span>
                          </div>

                          {/* Distance */}
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <img
                              src={distanceimage}
                              style={{
                                width: '25px',
                                height: '25px',
                                verticalAlign: 'middle',
                              }}
                              alt="distance icon"
                            />
                            <span style={{ lineHeight: '30px' }}>
                              {Math.round(currentPath.distanceCovered.toFixed(2))} mile(s)
                            </span>
                          </div>

                          {/* Odometer */}
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <img
                              src={odometerIcon}
                              style={{
                                width: '25px',
                                height: '25px',
                                verticalAlign: 'middle',
                              }}
                              alt="odometer icon"
                            />
                            <span style={{lineHeight: '30px' }}>
                              {currentPath.odometerMiles || 'N/A'} mi
                            </span>
                          </div>

                          {/* Address */}
                          <div
                            style={{
                              gridColumn: '1 / -1',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                          >
                            <LocationOnIcon style={{ fontSize: '25px', verticalAlign: 'middle' }} />
                            <span style={{ lineHeight: '20px' }}>
                              {currentPath.address?.line1 || ''}
                              {currentPath.address?.city ? `, ${currentPath.address.city}` : ''}
                              {currentPath.address?.state ? `, ${currentPath.address.state}` : ''}
                              {currentPath.address?.zip ? `, ${currentPath.address.zip}` : ''}
                              {currentPath.address?.country ? `, ${currentPath.address.country}` : ''}
                            </span>
                          </div>
                        </div>
                      </div>
                    </InfoWindow>
                  )}

                </Marker>
              )}
            </>
          )}
        </GoogleMap>
      ) : (
        <></>
      )}

      <Card
        style={{
          position: "absolute",
          bottom: "5px",
          left: "25px",
          paddingRight: "6px",
          width: "70%",
          display: "flex",
          flexDirection: "column",
          gap: "10px"
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Tooltip title="Play" placement="top-start"
            slotProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                  {
                    marginBottom: '0px',
                  },
                },
              },
            }}
          >
            <IconButton onClick={startSimulation}>
              <PlayCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Pause"
            placement="top-start"
            slotProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                  {
                    marginBottom: '0px',
                  },
                },
              },
            }}
          >
            <IconButton onClick={pauseSimulation}>
              <PauseCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Button onClick={stopSimulation}>Stop</Button>
          <div
            style={{
              flexGrow: 1,
              marginLeft: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              marginTop: "18px",
              marginRight: "6px"
            }}
          >
            <Slider
              size="small"
              value={sliderValue}
              onChange={handleSliderChange}
              aria-labelledby="playback-slider"
              // valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${value}%`}
            />
            {/* Time display */}
            <div
              style={{
                fontSize: "14px",
                color: "#555",
                alignSelf: "flex-end",
              }}
            >
              {formatTime(elapsedTime)} / {formatTime(totalDuration)}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
export default ReactMap;
