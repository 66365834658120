import React from "react";
import _get from "lodash/get";
import html2pdf from "html2pdf.js";
import moment from "moment";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import LogGrid from "./LogGrid";
import EmptyLogGrid from "./EmptyLogGrid";
import StatusButtons from "./StatusButtons";
import UpdateStatusModal from "./UpdateStatusModal";
import DateRangePicker from "../../components/DateRangePicker";
import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import { CircularProgress } from "@mui/material";
import { getDriverById } from "../../actions/driverActions";
import { setTimeZone } from "../../actions/app/appActions";
import {
  getDriverLogs,
  getDriverDaily,
  setSelectedLog,
  deleteDriverLog,
  getEditRequest,
  getDriverLogById,
  deleteRequest,
  getAllCalcus,
  updateDriverDaily,
} from "../../actions/logActions";
import {
  deleteAdminLogs,
  updateAdminLogs,
} from "../../actions/account/accountAdminActions";
import {
  formatDate,
  getDailyLogsDays,
  formatTime,
  formatRawDate,
  formatUsDate,
  formatDateTime,
  getEndOfDay,
  getStartOfDay,
  formatDateTimeUtc,
  formatTimeSec,
  getDailyLogsDayRange,
} from "../../actions/momentActions";
import CurrentStatusDetailTableDownload from "./CurrentStatusDetailTableDownload";
import { getAllDayEvents, filterDriverLogs, getAutoDrLimits } from "./helpers";
import {
  getLatestEvent,
  isSameDay,
  dateToday,
  formatDateRaw,
  getDayDiff,
} from "../../utils/momentHelpers";
import MessageDialog from "../Notifications/MessageDialog";
import DriverErrors from "./DriverErrors";
import SmsForm from ".././Setting/User/Forms/SmsForm";
import MultipleStatusModal from "./EditMultipleStatus/MultipleStatusModal";
import ReMultipleStatusModal from "./ReassignMultipleStatus/ReMultiStatusModal";
import AddMultipleStatusModal from "./AddMultipleStatus/AddMultiStatusModal";
import LogCertifyDownload from "./LogCertifyDownload";
import StatusViewMenu from "./StatusViewMenu";
import LaunchIcon from "@mui/icons-material/Launch";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SearchBar from "../../components/SearchBar";
import DailyStatusInfoTableDownload from "./DailyStatusInfoTableDownload";
import { pdfOptionsLocal } from "../../helpers/helpers";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },
  AppBar: {
    paddingLeft: theme.spacing(1),
    paddingRight: 12,
    paddingTop: 4,
  },
  paper: {
    padding: theme.spacing(1),
    // minHeight: 200,
    // overflow: "scroll",
    marginBottom: 10,
  },
  driverHeader: {
    textAlign: "center",
    fontWeight: 500,
  },
  driverTitle: {
    fontWeight: 400,
    fontSize: 12,
    marginTop: 10,
  },
  driverContent: {
    fontSize: 12,
    marginTop: 5,
    paddingLeft: 10,
  },
  carrierHeader: {
    textAlign: "center",
    fontWeight: 500,
    marginTop: 20,
  },
  textRight: {
    textAlign: "right",
  },
  textCenter: {
    textAlign: "center",
  },
  menuWrapper: {
    minHeight: 50,
    paddingTop: 6,
  },
  buttonContainer: {
    padding: 8,
  },
  statusCheckBoxWrapper: {
    paddingLeft: 25,
  },
  sepLine: {
    display: "inline-block",
    borderLeft: "1px solid lightgrey",
    height: "33px",
    position: "absolute",
  },
});

function Loader() {
  return (
    <Grid item xs={12} md={12} lg={12}>
      <div
        style={{
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        <CircularProgress />
      </div>
    </Grid>
  );
}

export class DailyStatusDetail extends React.Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(window.location.search);
    this.state = {
      open: false,
      downloading: false,
      loading: false,
      showAll: false,
      openRecap: false,
      isAllLog: props.isUnity ? true : values.isAllLog,
      selectedDate: values.selectedDate || new Date(),
      startDate: values.startDate || new Date(),
      endDate: values.endDate || new Date(),
      openCertify: false,
      isFmcsaSend: false,
      errors: [],
      isNotificationDialog: false,
      openSms: false,
      autoDrLimits: [],
      selectedLogs: [],
      fmcsa: [],
      multiLogs: [],
      tabValue: 0,
      dateError: "",
    };
  }

  componentDidMount() {}

  componentWillUnmount() {
    const timezone = _get(this, "props.defaultTimezone");
    this.props.setTimeZone(timezone);
  }

  reload = () => {
    this.setState({ selectedLogs: [] });
    this.searchLogs([this.state.startDate, this.state.endDate]);
  };

  getDriverDaily = async (endDate, driverId, days) => {
    return await this.props.getDriverDaily({
      driverId,
      days: days,
      selectedDate: endDate,
    });
  };

  getAllCalcus = async (driverId) => {
    // const driverCalcus = await this.props.getAllCalcus({ driverId });
    // return driverCalcus;
  };

  _searchLogs = async ({
    selDriver,
    timezone,
    startDate,
    endDate,
    showAll,
    driverId,
  }) => {
    const days = this.props.getDailyLogsDayRange(startDate, endDate, 1);
    const diaries = await this.getDriverDaily(endDate, driverId, days.length);
    const arrayOfPromises = days.map((_days) => {
      return this.props.getDriverLogs({
        showAll,
        driverId,
        ..._days,
      });
    });

    const _response = await Promise.all(arrayOfPromises);
    const response = _response.map((item) => {
      const selectedDate = item.selectedDate;
      const driverLogs = item.driverLogs || [];
      const driverLastLogs = item.driverLastLogs || {};
      const driverNextLogs = item.driverNextLogs || {};
      const diary = diaries.find((dia) => {
        return isSameDay(dia.logDate, selectedDate);
      });

      const _allEvents = getAllDayEvents(
        timezone,
        selectedDate,
        driverLogs,
        driverLastLogs,
        driverNextLogs
      );
      const allDayEvents = filterDriverLogs(_allEvents, []);

      return { logs: allDayEvents, diary, selectedDate };
    });

    this.setState({
      loading: false,
      multiLogs: response,
    });
  };

  searchLogs = async (selectedDates, driverId, showAll) => {
    const startDate = selectedDates[0];
    const endDate = selectedDates[1];
    const selDriver = await this.props.getDriverById({ driverId });

    this.setState({ startDate, endDate, loading: true });

    const timezone = _get(selDriver, "terminal.timezone");
    this.props.setTimeZone(timezone);

    await this._searchLogs({
      timezone,
      selDriver,
      startDate,
      endDate,
      showAll,
      driverId,
    });

    this.setState({ loading: false });
  };

  handleSearchChange = (params) => {
    const days = getDayDiff(params.end, params.start);
    if (days > 8) {
      this.setState({
        dateError: "Selection is limited to a maximum of 1 weeks.",
      });
      return null;
    } else {
      this.setState({ dateError: "" });
    }
    this.setState(
      {
        startDate: params.start,
        endDate: params.end,
        driverId: params.driverId,
      },
      () => {
        this.searchLogs([params.start, params.end], params.driverId);
      }
    );
  };

  onDownload = async () => {
    try {
      this.setState({ downloading: true });
      const driver = this.props.selectedDriver || {};
      const startDate = this.state.startDate;
      const endDate = this.state.endDate;
      const user = driver.driver || {};
      const carrier = driver.carrier || {};
      const carrierName = carrier.name || "";
      const userName = `${driver.firstName}-${driver.lastName}` || "";
      const dateRange =
        `${formatDateRaw(startDate)}-${formatDateRaw(endDate)}` || "";
      const fileName = `${userName}-${carrierName}-${dateRange}`;

      window.scrollTo(0, 0);
      const element = document.getElementById("print_log");

      pdfOptionsLocal.filename = fileName;
      pdfOptionsLocal.margin = [0.3, 0.1];
      await html2pdf().set(pdfOptionsLocal).from(element).toPdf().save();
      this.setState({ downloading: false });
    } catch (e) {
      this.setState({ downloading: false });
      console.log(e);
    }
  };

  render() {
    const { classes, logBusy, timezone, selectedDriver = {} } = this.props;
    const loading = this.state.loading || false;
    const dateError = this.state.dateError;
    const diaries = this.state.diaries || [];
    const driverCalcus = this.props.driverCalcus || [];

    const cycle = getLatestEvent(driverCalcus, "cycleResetTimestamp");
    const cycleStart = cycle && cycle.cycleResetTimestamp;

    return (
      <div>
        <Grid container className={classes.AppBar}>
          <Grid item xs={12}>
            <SearchBar
              options={{
                isEqp: false,
                isDriver: true,
                isStartDate: true,
                isEndDate: true,
                isTime: false,
                isTimeperiod: false,
                dl: true,
                isDownload: true,
                isDownloadIcon: true,
              }}
              loading={logBusy}
              onSearch={this.handleSearchChange}
              onDownload={this.onDownload}
            />
          </Grid>
        </Grid>

        <div className={classes.root}>
          <Grid container spacing={0}>
            {dateError && (
              <Grid item xs={12}>
                <Alert severity="error">{dateError}</Alert>
              </Grid>
            )}

            {this.state.downloading && <Loader />}
            {loading ? (
              <Loader />
            ) : (
              <>
                <Grid item xs={12} md={12} lg={12} style={{ marginTop: 60 }}>
                  {loading ? (
                    <EmptyLogGrid containerId="dutyStatusLogChart" />
                  ) : (
                    <div id="print_log">
                      {this.state.multiLogs &&
                        this.state.multiLogs.map((all, idx) => {
                          const item = all.logs || [];
                          const diary = all.diary || {};
                          const selectedDate = all.selectedDate || {};
                          // console.log("item == ", item);

                          let cycleData = null;
                          Array.isArray(driverCalcus) &&
                            driverCalcus.forEach((item) => {
                              const cycleDate = this.props.getEndOfDay(
                                item.cycleStartTimestamp
                              );
                              const date = this.props.getEndOfDay(selectedDate);

                              if (
                                item.status === "active" &&
                                cycleDate <= date
                              ) {
                                if (cycleData == null) {
                                  cycleData = item;
                                } else if (
                                  cycleDate >=
                                  this.props.getEndOfDay(
                                    cycleData.cycleStartTimestamp
                                  )
                                ) {
                                  cycleData = item;
                                }
                              }
                            });

                          const evtTime =
                            item[0].eventTime ||
                            item[1].eventTime ||
                            item[2].eventTime;

                          return (
                            <div
                              key={`id-${idx}`}
                              style={{ marginBottom: 60 }}
                              className="html2pdf__page-break"
                            >
                              <h2
                                style={{
                                  textAlign: "center",
                                  marginBottom: 20,
                                }}
                              >
                                {`DRIVER'S DAILY LOG`}
                              </h2>
                              <DailyStatusInfoTableDownload
                                dailyDiary={[diary]}
                                driverLogs={item}
                                selectedDriver={selectedDriver}
                                cycleData={cycleData}
                                selectedDate={evtTime}
                                driverLastLogs={{}}
                                driverNextLogs={{}}
                                formatRawDate={this.props.formatRawDate}
                              />
                              <LogGrid
                                timezone={timezone}
                                dailyDiary={diary}
                                driverLogs={item}
                                containerId={`dutyStatusLogChartLog-${idx}`}
                                formatTime={this.props.formatTime}
                                isAllLog={this.state.isAllLog}
                              />

                              <CurrentStatusDetailTableDownload
                                logBusy={logBusy}
                                profile={this.props.profile}
                                selectedDate={evtTime}
                                searchLogs={this.searchLogs}
                                isAllLog={false}
                                driverLogs={item}
                                dailyDiary={{}}
                                selectedLog={this.props.selectedLog}
                                formatTime={this.props.formatTime}
                                setSelectedLog={this.props.setSelectedLog}
                                formatRawDate={this.props.formatRawDate}
                                startOfDay={this.props.getStartOfDay}
                                showAll={false}
                                formatTimeSec={this.props.formatTimeSec}
                                isUnity={this.props.isUnity}
                              />
                            </div>
                          );
                        })}
                    </div>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const adminKey = _get(state, "authData.profile.adminId");
  let showAllLogs = _get(
    state,
    "authData.profile.accountProperties.settings.showAllLogs",
    false
  );
  let isDebug = _get(
    state,
    "authData.profile.accountProperties.settings.debug",
    true
  );
  if (adminKey && isDebug) {
    showAllLogs = true;
  }
  return {
    logBusy: _get(state, "logData.logBusy", false),
    adminAccess: _get(state, "authData.adminAccess", false),
    adminScope: _get(state, "authData.profile.scopes"),
    driverCalcus: _get(state, "logData.driverCalcus", []),
    selectedLog: _get(state, "logData.selectedLog", {}),
    driverBusy: _get(state, "driverData.driverBusy", false),
    selectedDriver: _get(state, "driverData.selectedDriver", {}),
    defaultTimezone: _get(state, "appData.defaultTimezone"),
    timezone: _get(state, "appData.timezone"),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    accountId: _get(state, "authData.accountId"),
    adminKey: adminKey,
    profile: _get(state, "authData.profile.roles"),
    showAllLogs,
    isDebug: !!adminKey,
    isQa: _get(state, "appData.appConfig"),
    appConfig: _get(state, "appData.appConfig"),
    isAdminDs: _get(state, "authData.isAdminDs", false),
    isUnity: _get(state, "appData.isUnity", false),
    isViewOnly: _get(state, "authData.isViewOnly", false),
    isAdminDre: _get(state, "authData.isAdminDre", false),
    isAdminDsMul: _get(state, "authData.isAdminDsMul", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTimeZone,
      getDriverById,
      getDriverDaily,
      getDriverLogs,
      formatDate,
      formatTime,
      formatRawDate,
      formatDateTime,
      formatUsDate,
      getEndOfDay,
      getStartOfDay,
      getDailyLogsDayRange,
      getDailyLogsDays,
      setSelectedLog,
      deleteDriverLog,
      getEditRequest,
      getDriverLogById,
      getAllCalcus,
      formatDateTimeUtc,
      formatTimeSec,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DailyStatusDetail)
);
