import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import EditIcon from "@mui/icons-material/Edit";
import NotFoundTableRow from "../../NotFoundTableRow";
import { LABELS as _LABELS } from "../../../language";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableRowTitle: {
    display: "inline-block",
    width: 50,
  },
  tableRow: {
    height: 30,
  },
  tableCell: {
    // fontSize: 10
  },
  capitalize: {
    textTransform: "capitalize",
  },
  dl: {
    fontSize: 10,
    fontWeight: 500,
  },
  EditIcon: {
    color: "#009be5",
    cursor: "pointer",
  },
  access: {
    maxWidth: 150,
  },
});

class ReduxTableRow extends React.Component {
  render() {
    const { classes, item = {} } = this.props;

    return (
      <TableRow hover className={classes.tableRow}>
        <TableCell className={`${classes.tableCell} ${classes.capitalize}`}>
          {item.type}
        </TableCell>

        <TableCell className={classes.tableCell}>
          {item.active ? "ACTIVE" : "INACTIVE"}
        </TableCell>

        <TableCell className={`${classes.tableCell} ${classes.access}`}>
          {item.isPush ? <Chip size="small" label={"Push"} /> : ""}{" "}
          {item.isEmail ? <Chip size="small" label={"Email"} /> : ""}{" "}
          {item.isSms ? <Chip size="small" label={"SMS"} /> : ""}
        </TableCell>

        <TableCell className={classes.tableCell}>
          <EditIcon
            className={classes.EditIcon}
            onClick={() => this.props.handleEdit(item)}
          />
        </TableCell>
      </TableRow>
    );
  }
}

export class ReduxTable extends React.Component {
  state = {
    order: "asc",
  };

  handleChangePage = (event, page) => {
    const { limit } = this.props;
    this.props.handleChange({ limit, page });
  };

  handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const { page } = this.props;
    this.props.handleChange({ limit, page });
  };

  render() {
    const { classes, items = [] } = this.props;
    const { page, limit } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{LABELS.name}</TableCell>
              <TableCell>{LABELS.status}</TableCell>
              <TableCell>{LABELS.type}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(items) &&
              items.map((item, i) => (
                <ReduxTableRow
                  item={item}
                  key={i}
                  classes={classes}
                  handleSms={this.props.handleSms}
                  handleEdit={this.props.handleEdit}
                  formatDateTime={this.props.formatDateTime}
                />
              ))}
          </TableBody>
        </Table>
        <NotFoundTableRow items={items} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={items.length}
          // disabled={itemArray.length !== 0 && itemArray.length < limit}
          rowsPerPage={limit}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          labelRowsPerPage={LABELS.rowsperpage}
        />
      </Paper>
    );
  }
}

ReduxTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReduxTable);
