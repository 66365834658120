import React from "react";
import { GoogleMap, InfoWindow, Marker, Polyline, useJsApiLoader } from "@react-google-maps/api";
import { gMapApiKey } from "../../utils/constants";
import { useState, useCallback } from "react";
import moment from 'moment';
import { makeStyles } from "@mui/styles";
import { useGoogleMaps } from "../../configs/GoogleMapsContext";



const useStyles = makeStyles((theme) => ({

  title: {
    fontSize: '1.2em',
    margin: 0,
    color: '#333',
  },
  state: {
    color: '#777',
  },
  date: {
    textAlign: "center",
    fontSize: '0.9em',
    color: '#555',
    marginTop: '5px', 
  },
}));



function ReactMap({ trip }) {

  const { isLoaded } = useGoogleMaps()

  function formatDateTime(date) {
    return moment(date).format("DD MMM @ h:mm a");
  }

  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const classes = useStyles();

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    if (trip.items && trip.items.length > 0) {
      trip.items.forEach((item) => {
        const coords = item.coordinates;
        if (coords && coords.length === 2) {
          const lat = parseFloat(coords[0]);
          const lng = parseFloat(coords[1]);
          if (!isNaN(lat) && !isNaN(lng)) {
            bounds.extend({ lat, lng });
          }
        }
      });
    }

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
    }

    setMap(map);
  }, [trip.items]);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  const pathCoordinates = trip.items
    .filter(item => item.coordinates && item.coordinates.length === 2)
    .map(item => ({
      lat: parseFloat(item.coordinates[0]),
      lng: parseFloat(item.coordinates[1])
    }));

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            height: "450px",
            display: "block",
            flexDirection: "column",
            width: "100%",
          }}
          zoom={12}

          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {/* Render markers for each item in the trip */}
          {trip.items &&
            trip.items.map((item, idx) => {
              const coords = item.coordinates;
              if (coords && coords.length === 2) {
                const lat = parseFloat(coords[0]);
                const lng = parseFloat(coords[1]);

                if (!isNaN(lat) && !isNaN(lng)) {
                  return (
                    <Marker
                      key={`id-${idx}`}
                      position={{ lat, lng }}
                      label={`${idx + 1}`}
                      onClick={() => setSelectedMarker(item)}
                    />
                  );
                }
              }
              return null;
            })}
         
          {pathCoordinates.length > 1 && (
            <Polyline
              path={pathCoordinates}
              options={{
                strokeColor: "#0088FF",
                strokeWeight: 4,
                strokeOpacity: 0.6,
                defaultVisible: true,
              }}
            />
          )}
          {selectedMarker && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedMarker.coordinates[0]),
                lng: parseFloat(selectedMarker.coordinates[1]),
              }}
              options={{ pixelOffset: new window.google.maps.Size(0, -10) }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div className={classes.infoWindow}>
                <h4 className={classes.title}>{selectedMarker.city}, <span className={classes.state}>{selectedMarker.state}</span></h4>
                <p className={classes.created}>{formatDateTime(selectedMarker.created)}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : (
        <></>
      )}
    </>
  );
}

export default ReactMap;
