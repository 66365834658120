import React from "react";
import _get from "lodash/get";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import Alert from '@mui/material/Alert';
// import { J1939_parse_string } from "../../../utils/dtcDecode";
import LinearProgress from "@mui/material/LinearProgress";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  AlignCenter: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  AlignBtn: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  agriExm: {
    border: "1px solid grey",
    textTransform: "uppercase",
    flexBasis: "25%",
    flexShrink: 0,
    padding: 4,
    backgroundColor: "white",
    textAlign: "center",
    color: "black",
    display: "inline-block",
    width: 110,
    borderRadius: 5,
    fontWeight: 500,
  },
});

function kmToMi(km) {
  try {
    let speed = parseFloat(km) * 0.621371;

    return speed.toFixed(2);
  } catch (e) {
    return 0;
  }
}

function Loading({ message }) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div style={{ padding: 45 }}>
      <h2 style={{ textAlign: "center" }}>
        {message ? message : "Connecting..."}
      </h2>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
}

function ImageDetail(props) {
  const baseUrl = _get(props, "imageRes.baseUrl");
  const urlEndpoint = _get(props, "imageRes.images[0].URL");
  const imageUrl = `${baseUrl}/${urlEndpoint}`;
  const speed = _get(props, "imageRes.images[0].Speed", "0");
  const _time = _get(props, "imageRes.images[0].StartDateTime");
  const eventTime = _time
    ? `${props.formatDate(_time)} ${props.formatTime(_time)}`
    : "";

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <div style={{ marginTop: 20 }}>
          <Typography variant="body2">
          {props.streamUrl && (
              <iframe
                src={props.streamUrl}
                width="100%"
                height="500"
                frameBorder="0"
                allowFullScreen
                left={'auto!important'}
              ></iframe>
            )}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}

function DeviceDetail(props) {
  const detailRes = props.detailRes || {};
  const isOnline = detailRes && detailRes.Online;
  // const baseUrl = _get(props, "imageRes.baseUrl");
  // const urlEndpoint = _get(props, "imageRes.images[0].URL");
  // const imageUrl = `${baseUrl}/${urlEndpoint}`;
  // const speed = _get(props, "imageRes.images[0].Speed", "0");
  const _lastContact = _get(props, "detailRes.LastContact");
  const lastContact = _lastContact
    ? `${props.formatDate(_lastContact)} ${props.formatTime(_lastContact)}`
    : "";
  const lat = detailRes.Latitude;
  const lng = detailRes.Longitude;

  return (
    <Grid container>
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="body2">
          Status: {isOnline ? `Online` : "Offline"}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="body2">
          Last Contact: {`${lastContact}`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="body2">
          Vehicle ID: {`${detailRes.VehicleID}`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Typography variant="body2">
          Driver ID: {`${detailRes.DriverID}`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="body2">
          Location:
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
          >{` View on map`}</a>
        </Typography>
      </Grid>
    </Grid>
  );
}

export class DashcamModal extends React.Component {
  state = {
    loading: false,
    loadingPhoto: false,
    streamUrl: '',
    message: "",
  };

  componentDidMount() {
    this.getDcDetail();
    // this.getDcLiveview();
  }

  getDcDetail = async () => {
    try {
      this.setState({ loading: true });
      const devicesId = _get(this, "props.data.driver.dcId");
      const detailRes = await this.props.getDcDetail({ devicesId });

      this.setState({ detailRes, loading: false });
      if (detailRes && detailRes.Online) {
        this.getDcLiveview();
      }
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
    }
  };

  getDcLiveview = async () => {
    try {
      this.setState({ loadingPhoto: true });
      const devicesId = _get(this, "props.data.driver.dcId");
      const imageRes = await this.props.getDcLiveview({ devicesId });
      const streamUrl = _get(imageRes, "videos.MoreInfo.WidgetUrl");
      if (streamUrl) {
        this.setState({ streamUrl: streamUrl });
      }
      this.setState({ loadingPhoto: false });
    } catch (e) {
      this.setState({ loadingPhoto: false });
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;
    // const telemetrics = this.props.data || {};
    const loading = this.state.loading;
    const loadingPhoto = this.state.loadingPhoto;
    const imageRes = this.state.imageRes;
    const detailRes = this.state.detailRes;
    const streamUrl = this.state.streamUrl;
    const isSmallScreen = window.innerWidth < 500;
    // const baseUrl = _get(this, "state.imageRes.baseUrl");
    // const urlEndpoint = _get(this, "state.imageRes.images[0].URL");
    // const imageUrl = `${baseUrl}/${urlEndpoint}`;
    // const speed = _get(this, "state.imageRes.images[0].Speed", "0");
    // const _time = _get(this, "state.imageRes.images[0].StartDateTime");
    // const eventTime = _time
    //   ? `${this.props.formatDate(_time)} ${this.props.formatTime(_time)}`
    //   : "";
    const isOnline = detailRes && detailRes.Online;

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"sm"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>
            <span style={{ top: -4, position: "relative" }}>{"Dashcam"}</span>
            {isOnline && <SignalCellularAltIcon style={{ color: "green" }} />}
          </Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ marginBottom: 10 }}>
          {loading ? (
            <Loading />
          ) : (
            <DeviceDetail
              detailRes={detailRes}
              formatDate={this.props.formatDate}
              formatTime={this.props.formatTime}
            />
          )}
          {loadingPhoto && <Loading message="Connecting..." />}

          {isOnline && (
            <ImageDetail
              streamUrl={streamUrl}
              getDcLiveview={this.getDcLiveview}
              formatDate={this.props.formatDate}
              formatTime={this.props.formatTime}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withRouter(DashcamModal));
