import React from "react";
import DailyStatusMultiDownload from "../../DailyStatus/DailyStatusMultiDownload";

function Report(props) {
  return (
    <div
      style={{
        padding: "40px 10px 40px 40px",
      }}
    >
      <DailyStatusMultiDownload />
    </div>
  );
}

export default Report;
