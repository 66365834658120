import _get from "lodash/get";
import Badge from "@mui/material/Badge";
import html2pdf from "html2pdf.js";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { formatTime, formatDate } from "../../../actions/momentActions";
import DutyStatus from "../../../components/DutyStatus";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import Button from "@mui/material/Button";
import { pdfOptionsLocal } from "../../../helpers/helpers";
import { formatDateRaw, isSameDay } from "../../../utils/momentHelpers";
import Typography from "@mui/material/Typography";

function RenderTelemetrics(item) {
  if (item.eventCode !== "DRIVING") {
    return null;
  }
  const _speed = _get(item, "telemetrics.speed", 0);
  const speed = _speed ? `Speed  ${parseInt(_speed)} M/hr(s)` : "";

  return (
    <div>
      <small>{speed}</small>
    </div>
  );
}

function getAppName(name) {
  if (!name) {
    return "";
  }
  const nameArray = name.split(":");
  const first = nameArray && nameArray[0];
  let app = "";
  if (first === "I") {
    app = "Apple";
  } else if (first === "A") {
    app = "Android";
  }
  return app;
}

function RenderTableRows(props) {
  const dispatch = useDispatch();
  const index = props.index;
  const item = props.item || {};
  const classes = props.classes || {};
  const driver = item.driver || {};
  const vehicle = item.vehicle || {};
  const connected = item.connected || false;
  const certPending = item.certPending || "";
  const version = item.version || "";
  const remaining = _get(item, "remaining", {});
  const appName = getAppName(item.version);

  return (
    <TableRow hover style={{ cursor: "pointer" }}>
      <TableCell style={{ textTransform: "capitalize" }}>
        {driver.firstName} {driver.lastName}
        <div>{vehicle.name ? `Vehicle: ${vehicle.name}` : ""}</div>
      </TableCell>
      <TableCell>
        <RenderTelemetrics item={item} />
        {item.location}
      </TableCell>
      <TableCell>
        <DutyStatus
          status={item}
          eventType={item.eventType}
          eventCode={item.eventCode}
          annotation={item.annotation}
          eventSubType={item.eventSubType}
          styles={{ border: 0, textAlign: "left" }}
        />
      </TableCell>

      <TableCell>
        <div>{`Break: ${remaining.break}`}</div>
        <div>{`Drive: ${remaining.drive}`}</div>
        <div>{`OnDuty: ${remaining.onDuty}`}</div>
        <div>{`Cycle: ${remaining.cycle}`}</div>
      </TableCell>

      <TableCell>
        <div>
          {dispatch(formatTime(item.lastContact))}
          <br />
          {dispatch(formatDate(item.lastContact))}
        </div>
      </TableCell>
    </TableRow>
  );
}

export default function RenderTable(props) {
  const [downloading, setDownloading] = useState(false);

  async function download() {
    try {
      setDownloading(true);

      window.scrollTo(0, 0);
      const element = document.getElementById("hos-report");

      pdfOptionsLocal.filename = "hos-report";
      pdfOptionsLocal.margin = [0.3, 0.1];
      await html2pdf().set(pdfOptionsLocal).from(element).toPdf().save();
      setDownloading(false);
    } catch (e) {
      console.log(e);
    }
  }

  const logs = props.items || [];
  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Button onClick={download} size="small">
          {downloading ? "Downloading..." : "Download"}
        </Button>
      </div>
      <Grid item xs={12} md={12} lg={12} id="hos-report">
        <Grid item xs={12} md={12} lg={12} id="hos-report">
          <Typography
            variant="h5"
            component="h2"
            style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}
          >
            {`HOS RECAP`}
            <br />
            <small>{"Hours of Service Summary"}</small>
          </Typography>
        </Grid>

        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{"Name"}</TableCell>
                <TableCell>{"Location"}</TableCell>
                <TableCell>{"Status"}</TableCell>
                <TableCell>{"Recap"}</TableCell>
                <TableCell>{"Update"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((item, idx) => {
                return (
                  <RenderTableRows
                    index={idx}
                    key={`id-${idx}-${item._id}`}
                    item={item}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
