import _get from "lodash/get";
import React from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Alert, AlertTitle } from "@mui/lab";
import StatusAuditTable from "./StatusAuditTable";
import {
  getStatusAuditAdmin,
  undoAdminLog,
} from "../../../actions/account/accountAdminActions";
import {
  getDailyLogsDays,
  formatTime,
  formatRawDate,
  formatDateTime,
  getEndOfDay,
  getStartOfDay,
  formatDateTimeUtc,
  formatTimeSec,
} from "../../../actions/momentActions";
import { LABELS as _LABELS } from "../../../language";

const LABELS = _LABELS.dutyPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapperButton: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
});

export class AddDailyStatusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      undoing: false,
      errorMessage: "",
      successMessage: "",
      selEvent: {},
      auditLogs: [],
    };
  }

  componentDidMount() {
    this.getStatusAuditAdmin();
  }

  getStatusAuditAdmin = async () => {
    try {
      this.setState({
        loading: true,
      });
      // const values = queryString.parse(window.location.search);
      const accountId = this.props.accountId;
      const driverId = this.props.driverId;
      const eventTime = this.props.selectedDate;
      const payload = {
        accountId,
        driverId,
        eventTime,
      };

      const response = await this.props.getStatusAuditAdmin(payload);

      if (response && Array.isArray(response)) {
        this.setState({
          auditLogs: response,
          loading: false,
        });
      }
    } catch (e) {}
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    const auditLogs = this.state.auditLogs || [];
    const isSmallScreen = window.innerWidth < 400;

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={!!this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.handleClose}>
          <Typography>{LABELS.adminEvents}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ marginBottom: 25 }}>
          {auditLogs ? (
            <>
              {!loading && (
                <StatusAuditTable
                  isAllLog={auditLogs}
                  driverLogs={auditLogs}
                  undoAdminLog={this.props.undoAdminLog}
                  formatTime={this.props.formatTime}
                  formatRawDate={this.props.formatRawDate}
                  formatDateTime={this.props.formatDateTime}
                  formatTimeSec={this.props.formatTimeSec}
                  getStatusAuditAdmin={this.getStatusAuditAdmin}
                />
              )}
            </>
          ) : (
            <Alert severity="warning">
              <AlertTitle>{LABELS.noDutyStatusFound}</AlertTitle>
            </Alert>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driverId: _get(state, "driverData.selectedDriver.driver.id"),
    accountId: _get(state, "authData.accountId"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getStatusAuditAdmin,
      formatTime,
      formatRawDate,
      formatDateTime,
      formatTimeSec,
      getEndOfDay,
      getStartOfDay,
      formatDateTimeUtc,
      getDailyLogsDays,
      undoAdminLog
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AddDailyStatusModal)
);
