import React from "react";
import _get from "lodash/get";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import DateRangePicker from "../../components/DateRangePicker";
import DutyStatus from "../../components/DutyStatus";
import LogGrid from "./LogGrid";
import EmptyLogGrid from "./EmptyLogGrid";
import {
  CircularProgress,
  MenuItem,
  Select,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { getDriverById } from "../../actions/driverActions";
import { setTimeZone } from "../../actions/app/appActions";
import {
  getDriverLogs,
  getDriverDaily,
  setSelectedLog,
  getEditRequest,
  getDriverLogById,
  getAllCalcus,
  updateDriverDaily,
} from "../../actions/logActions";
import { updateAdminLogs } from "../../actions/account/accountAdminActions";
import {
  getDailyLogsDays,
  formatTime,
  formatDateTime,
  formatRawDate,
  formatDateTimeUtc,
  formatDateTimeToUtc,
  getDailyLogsDayRange,
  getDaysCount,
  formatTimeSec,
  formatUsDate,
  formatDate,
} from "../../actions/momentActions";
import { getAllDayEvents, filterDriverLogs } from "./helpers";
import { getFireBaseErrors } from "../../actions/adminDashActions";
import { getFmcsaHistoryCount } from "../../actions/fmcsaActions";
import {
  RECORD_ORIGIN_ABV,
  SUB_EVENT_TYPE_OPTIONS,
  RECORD_ORIGIN_OPTIONS,
  EVENT_CODES_OPTIONS,
  EVENT_TYPE_OPTIONS,
} from "../../constantsStatus";
import { ALL_STATES } from "../../constants";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },
  tableRowCellXm: {
    minWidth: 10,
  },
  tableRowCell: {
    minWidth: 20,
  },
  tableRowCellSm: {
    minWidth: 30,
  },
  tableRowCellMd: {
    minWidth: 50,
  },
  tableRowCellLg: {
    minWidth: 70,
  },
  tableRowCellXl: {
    minWidth: 90,
  },
  tableRowCellXxl: {
    minWidth: 110,
  },
  tableRowCellDate: {
    minWidth: 175,
  },
  capital: {
    textTransform: "capitalize",
  },
});

class ReduxTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
    };
  }

  render() {
    const isCoDrivers = this.props.isCoDrivers || false;
    const editDisabled = this.props.editDisabled || false;
    const isAll = this.props.isAll || false;
    const { classes, timezone, item = {} } = this.props;
    const vehicle = item.vehicle || {};
    const driver = item.driver || {};

    const time = item.eventTime
      ? this.props.formatDateTimeUtc(item.eventTime, this.props.timezone)
      : "";

    if (item.shiftReset || item.cycleStartTimestamp) {
      return null;
    }

    // const eventTime = this.props.formatTimeSec(item.eventTime, timezone)
    //
    // const eventDate = this.props.formatRawDate(item.eventTime, timezone);

    return (
      <TableRow
        hover
        className={`${classes.table}`}
        onClick={() => this.props.handleRowClick(item)}
      >
        <TableCell className={`${classes.tableRowCell}`}>
          {item.isSaved ? (
            <CheckCircleIcon color={"success"} />
          ) : item.isEdited ? (
            <EditIcon style={{ color: "lightgrey" }} />
          ) : null}
        </TableCell>
        {isCoDrivers && (
          <TableCell className={`${classes.tableRowCell} ${classes.capital}`}>
            {`${driver.firstName} ${driver.lastName}`}
          </TableCell>
        )}
        {isAll && (
          <TableCell className={`${classes.tableRowCell} ${classes.capital}`}>
            {`${vehicle.name}`}
          </TableCell>
        )}
        <TableCell className={`${classes.tableRowCellDate}`}>
          <TextField
            id={`${item._id}-eventTime`}
            size="small"
            variant="standard"
            value={time}
            type="datetime-local"
            InputProps={{
              inputProps: { step: "1" },
            }}
            disabled={editDisabled}
            onChange={(e) => {
              const date = e.target.value;
              item.eventTime = new Date(date);
              this.props.onItemEdit(item);
            }}
          />
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <DutyStatus
            status={item}
            tableClass={""}
            eventType={item.eventType}
            eventCode={item.eventCode}
            annotation={item.annotation}
            eventSubType={item.eventSubType}
          />
        </TableCell>
        <TableCell className={`${classes.tableRowCellXxl}`}>
          <TextField
            fullWidth
            size="small"
            variant="standard"
            id={"location"}
            placeholder="location"
            disabled={editDisabled}
            onChange={(e) => {
              item.location = e.target.value;
              this.props.onItemEdit(item);
            }}
            value={this.state.location || item.location}
            InputProps={{
              id: `${item._id}-location`,
              disableUnderline: true,
              className: classes.searchInput,
            }}
          />
        </TableCell>
        {isAll && (
          <TableCell className={`${classes.tableRowCell}`}>
            <FormControl className={classes.formControl} size="small">
              <Select
                variant="standard"
                id={"state"}
                className={`${classes.select}`}
                value={item.state || "-"}
                onChange={(e) => {
                  item.state = e.target.value;
                  this.props.onItemEdit(item);
                }}
                disabled={editDisabled}
                inputProps={{
                  style: { border: 0 },
                  id: `${item._id}-state`,
                  name: `${item._id}-state`,
                }}
              >
                <MenuItem value={"-"}>{"State"}</MenuItem>
                {ALL_STATES.map((_item, i) => (
                  <MenuItem key={_item.id} value={_item.id}>
                    {_item.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>
        )}
        <TableCell className={`${classes.tableRowCell}`}>
          <div>
            <TextField
              size="small"
              variant="standard"
              fullWidth
              id={"odometer"}
              placeholder="Odometer"
              type="number"
              disabled={editDisabled}
              onChange={(e) => {
                item.odometer = e.target.value;
                this.props.onItemEdit(item);
              }}
              value={this.state.odometer || item.odometer}
              InputProps={{
                id: `${item._id}-odometer`,
                disableUnderline: true,
                className: classes.searchInput,
              }}
            />
            {!item.odometer && item.isEdited ? (
              <span className={classes.odoError}>Required</span>
            ) : parseInt(item.odometer) > 9999999 ? (
              <span className={classes.odoError}>Invalid</span>
            ) : (
              ""
            )}
          </div>
        </TableCell>
        <TableCell className={`${classes.tableRowCellLg}`}>
          <div>
            <TextField
              size="small"
              variant="standard"
              fullWidth
              id={"engineHours"}
              placeholder="engineHours"
              type="number"
              disabled={editDisabled}
              onChange={(e) => {
                item.engineHours = e.target.value;
                this.props.onItemEdit(item);
              }}
              value={item.engineHours}
              InputProps={{
                id: `${item._id}-engineHours`,
                disableUnderline: true,
                className: classes.searchInput,
              }}
            />
          </div>
        </TableCell>
        <TableCell className={`${classes.tableRowCellLg}`}>
          <div>
            <TextField
              size="small"
              variant="standard"
              fullWidth
              id={"sequenceId"}
              placeholder="sequenceId"
              type="number"
              disabled={editDisabled}
              onChange={(e) => {
                item.sequenceId = e.target.value;
                this.props.onItemEdit(item);
              }}
              value={item.sequenceId}
              InputProps={{
                id: `${item._id}-sequenceId`,
                disableUnderline: true,
                className: classes.searchInput,
              }}
            />
          </div>
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          {`${RECORD_ORIGIN_ABV[item.recordOrigin]}`}
        </TableCell>
        <TableCell className={`${classes.tableRowCellXxl}`}>
          <TextField
            fullWidth
            size="small"
            id={"latitude"}
            variant="standard"
            placeholder="Latitude"
            disabled={editDisabled}
            onChange={(e) => {
              item.coordinates[0] = e.target.value;
              this.props.onItemEdit(item);
            }}
            value={(item.coordinates && item.coordinates[0]) || 0}
            InputProps={{
              id: `${item._id}-Latitude`,
              disableUnderline: true,
              className: classes.searchInput,
            }}
          />
        </TableCell>
        <TableCell className={`${classes.tableRowCellXxl}`}>
          <TextField
            fullWidth
            size="small"
            variant="standard"
            id={"longitude"}
            placeholder="Longitude"
            disabled={editDisabled}
            onChange={(e) => {
              item.coordinates[1] = e.target.value;
              this.props.onItemEdit(item);
            }}
            value={(item.coordinates && item.coordinates[1]) || 0}
            InputProps={{
              id: `${item._id}-Longitude`,
              disableUnderline: true,
              className: classes.searchInput,
            }}
          />
        </TableCell>

        {isAll && (
          <>
            <TableCell className={`${classes.tableRowCellXm}`}>
              <FormControl className={classes.formControl} size="small">
                <Select
                  variant="standard"
                  id={"eventCode"}
                  disabled={editDisabled}
                  value={item.eventCode || "-"}
                  onChange={(e) => {
                    item.eventCode = e.target.value;
                    this.props.onItemEdit(item);
                  }}
                  inputProps={{
                    name: `${item._id}-eventcode`,
                    id: `${item._id}-eventcode`,
                  }}
                >
                  {EVENT_CODES_OPTIONS.map((_item, i) => (
                    <MenuItem key={_item.id} value={_item.id}>
                      {_item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell className={`${classes.tableRowCellXm}`}>
              <FormControl className={classes.formControl} size="small">
                <Select
                  variant="standard"
                  id={"eventSubType"}
                  disabled={editDisabled}
                  value={item.eventSubType || "-"}
                  onChange={(e) => {
                    item.eventSubType = e.target.value;
                    this.props.onItemEdit(item);
                  }}
                  inputProps={{
                    name: `${item._id}-eventSubType`,
                    id: `${item._id}-eventSubType`,
                  }}
                >
                  {SUB_EVENT_TYPE_OPTIONS.map((_item, i) => (
                    <MenuItem key={_item.id} value={_item.id}>
                      {_item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell className={`${classes.tableRowCell}`}>
              <FormControl className={classes.formControl} size="small">
                <Select
                  variant="standard"
                  id={"recordOrigin"}
                  disabled={editDisabled}
                  value={item.recordOrigin || "-"}
                  onChange={(e) => {
                    item.recordOrigin = e.target.value;
                    this.props.onItemEdit(item);
                  }}
                  inputProps={{
                    id: `${item._id}-recordOrigin`,
                    name: `${item._id}-recordOrigin`,
                  }}
                >
                  {RECORD_ORIGIN_OPTIONS.map((_item, i) => (
                    <MenuItem
                      key={_item.id}
                      value={_item.id}
                      style={{ textTransform: "capitalize" }}
                    >
                      {_item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell className={`${classes.tableRowCell}`}>
              <FormControl className={classes.formControl} size="small">
                <Select
                  variant="standard"
                  id={"eventType"}
                  disabled={editDisabled}
                  value={item.eventType || "-"}
                  onChange={(e) => {
                    item.eventType = e.target.value;
                    this.props.onItemEdit(item);
                  }}
                  inputProps={{
                    id: `${item._id}-eventType`,
                    name: `${item._id}-eventType`,
                  }}
                >
                  {EVENT_TYPE_OPTIONS.map((_item, i) => (
                    <MenuItem key={_item.id} value={_item.id}>
                      {_item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell className={`${classes.tableRowCellXxl}`}>
              <div>
                <TextField
                  size="small"
                  variant="standard"
                  id={"notes"}
                  className={classes.font}
                  fullWidth
                  placeholder={"notes"}
                  disabled={editDisabled}
                  InputProps={{
                    id: `${item._id}-notes`,
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                  onChange={(e) => {
                    item.annotation = e.target.value;
                    this.props.onItemEdit(item);
                  }}
                  value={item.annotation}
                />
              </div>
            </TableCell>
          </>
        )}
      </TableRow>
    );
  }
}

export class DailyStatusDetail extends React.Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(window.location.search);

    this.state = {
      loading: true,
      selectedDate: values.selectedDate || new Date(),
      startDate: values.startDate || new Date(),
      endDate: values.endDate || new Date(),
      errors: [],
      eventType: "",
      eventSubType: "",
      isEdit: false,
      multiLogs: [],
      tabValue: 0,
      tableMaxHgt: 1000,
      editDisabled: false,
    };
  }

  componentDidMount() {
    this.searchLogs([this.state.startDate, this.state.endDate]);
  }

  componentWillUnmount() {
    const timezone = _get(this, "props.defaultTimezone");
    this.props.setTimeZone(timezone);
  }

  reload = () => {
    this.searchLogs([this.state.startDate, this.state.endDate]);
  };

  searchLogs = async (selectedDates, showAll) => {
    this.setDateinQuery(selectedDates);
    const startDate = selectedDates[0];
    const endDate = selectedDates[1];
    const driverId = _get(this, "props.match.params.driverId");
    const selDriver = await this.props.getDriverById({ driverId });
    const dailyDiary = await this.getDriverDaily(startDate, endDate);
    await this.getAllFmcsa(startDate, endDate);

    let _coDrivers = {};

    for (let i = 0; i < dailyDiary.length; i++) {
      const _dds = (dailyDiary[i] && dailyDiary[i].coDriver) || [];
      if (_dds && Array.isArray(_dds)) {
        for (var j = 0; j < _dds.length; j++) {
          const _dd = _dds[j] || {};
          if (_dd.id) {
            _coDrivers[_dd.id] = _dd;
          }
        }
      }
    }
    const coDrivers = Object.values(_coDrivers);
    const coDrIds = Object.keys(_coDrivers);
    const isCoDrivers = coDrivers.length > 0;

    this.setState({
      startDate,
      endDate,
      loading: true,
      dailyDiary,
      coDrivers,
      isCoDrivers,
    });

    const timezone = _get(selDriver, "terminal.timezone");
    this.props.setTimeZone(timezone);

    const driverLogs = await this._searchLogs({
      timezone,
      selDriver,
      startDate,
      endDate,
      showAll,
      driverId,
      coDrIds,
    });
    this.setState({ loading: false, isEdit: false });
  };

  _searchLogs = async ({
    selDriver,
    timezone,
    startDate,
    endDate,
    showAll,
    driverId,
    coDrIds,
  }) => {
    const days = this.props.getDailyLogsDayRange(startDate, endDate, 1);

    const arrayOfPromises = days.map((_days) => {
      return this.props.getDriverLogs({
        showAll,
        driverId,
        coDrIds,
        ..._days,
      });
    });

    let allDriverLogs = [];
    const allCalcus = await this.getAllCalcus();
    const _response = await Promise.all(arrayOfPromises);
    const response = _response.map((item) => {
      const selectedDate = item.selectedDate;
      const driverLogs = item.driverLogs || [];
      const driverLastLogs = item.driverLastLogs || {};
      const driverNextLogs = item.driverNextLogs || {};

      allDriverLogs = [...allDriverLogs, ...driverLogs];

      const _allEvents = getAllDayEvents(
        timezone,
        selectedDate,
        driverLogs,
        driverLastLogs,
        driverNextLogs
      );
      const allDayEvents = filterDriverLogs(_allEvents, allCalcus);

      return allDayEvents;
    });

    const allDayEvents = filterDriverLogs(allDriverLogs, allCalcus);

    this.setState({
      loading: false,
      multiLogs: response,
      driverLogs: allDriverLogs,
      allDayEvents: allDayEvents,
    });
    return allDriverLogs;
  };

  getAllCalcus = async () => {
    const driverId = _get(this, "props.match.params.driverId");
    const driverCalcus = await this.props.getAllCalcus({ driverId });
    this.setState({ driverCalcus });
    return driverCalcus;
  };

  setDateinQuery = (selectedDates) => {
    const _startDate = selectedDates[0];
    const _endDate = selectedDates[1];
    const startDate = this.props.formatRawDate(_startDate);
    const endDate = this.props.formatRawDate(_endDate);
    const currenUrl = _get(this, "props.history.location.pathname");

    this.props.history.push({
      pathname: currenUrl,
      search: `?startDate=${startDate}&endDate=${endDate}`,
    });
  };

  getDriverDaily = async (startDate, endDate) => {
    const days = this.props.getDaysCount(startDate, endDate);
    const driverId = _get(this, "props.match.params.driverId");
    return await this.props.getDriverDaily({
      driverId,
      days: days,
      selectedDate: this.props.formatRawDate(endDate),
    });
  };

  onItemEdit = (_sel) => {
    let _allDayEvents = this.state.allDayEvents || [];
    let ___allDayEvents = _allDayEvents.map((item) => {
      if (_sel._id === item._id) {
        _sel.isEdited = true;
        return _sel;
      } else {
        return item;
      }
    });
    this.setState({ allDayEvents: ___allDayEvents, isEdit: true });
  };

  getAllFmcsa = async (startDate, endDate) => {
    try {
      this.setState({ isFmcsaSend: false });
      const accountId = this.props.accountId;
      const driverId = _get(this, "props.match.params.driverId");

      const payload = {
        accountId,
        driverId,
        to: endDate,
        from: startDate,
      };

      const fmcsaCount = await this.props.getFmcsaHistoryCount(payload);
      // console.log("fmcsaCount :::: ", typeof fmcsaCount == "number");
      if (fmcsaCount && typeof fmcsaCount == "number" && fmcsaCount > 0) {
        this.setState({ editDisabled: true });
      } else {
        this.setState({ editDisabled: false });
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
  };

  onSave = async () => {
    try {
      this.setState({ saving: true });
      const _logs = this.state.allDayEvents || [];
      const logs = _logs.filter((item) => item.isEdited);

      const arrayOfPromises = logs.map((item, idx) => {
        item.eventTime = this.props.formatDateTimeToUtc(
          item.eventTime,
          this.props.timezone
        );
        return this._saveLogs(item, idx);
      });
      await Promise.all(arrayOfPromises);
      this.setState({ saving: false });
      this.reload();
    } catch (e) {
      // console.log(e);
      this.setState({ saving: false });
    }
  };

  _saveLogs = async (log, idx) => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const logs = this.state.allDayEvents || [];
          const response = await this.props.updateAdminLogs(log);
          if (response && response._id) {
            let _allDayEvents = logs.map((item) => {
              if (response._id === item._id) {
                item.isSaved = true;
              }
              return item;
            });
            this.setState({ allDayEvents: _allDayEvents });
          }

          resolve(response);
        } catch (e) {
          resolve(null);
        }
      }, idx * 100);
    });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleRowClick = (item) => {
    let index = 0;
    for (let i = 0; i < this.state.multiLogs.length; i++) {
      let _logs = this.state.multiLogs[i];
      for (var j = 0; j < _logs.length; j++) {
        let _log = _logs[j] || {};
        if (_log._id === item._id) {
          index = i;
          break;
        }
      }
    }
    this.setState({ tabValue: index + 1, tableMaxHgt: 500 });
  };

  handleMouseLeave = () => {
    // this.setState({ tabValue: 0, tableMaxHgt: 1000 });
  };

  render() {
    if (!this.props.adminKey) {
      return "Access Denied";
    }
    const { classes, selectedDriver = {}, timezone } = this.props;
    const { loading, selectedDate, saving } = this.state;
    const { isCoDrivers, isAll, coDrivers, tabValue } = this.state;
    const { driverLogs = [], allDayEvents } = this.state;
    const dailyDiary = this.state.dailyDiary;
    const editDisabled = this.state.editDisabled;

    return (
      <div>
        <div className={classes.root}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} lg={12}>
              {driverLogs && driverLogs.length > 700 ? (
                <Alert severity="error">
                  {`${driverLogs.length} logs were recorded. Driver may have any issue. Check driver's all logs.`}
                </Alert>
              ) : driverLogs && driverLogs.length > 200 ? (
                <Alert severity="warning">
                  {`${driverLogs.length} logs were recorded. Driver have an issue. Check driver's all logs.`}{" "}
                </Alert>
              ) : null}
              {editDisabled && (
                <Alert
                  style={{ marginBottom: 10 }}
                  severity="warning"
                >{`Logs for one of the selected day(s) already tranfered to FMCSA.`}</Alert>
              )}

              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} lg={4}>
                    <DateRangePicker
                      id="dateRangePicker"
                      timezone={timezone}
                      disabled={this.state.loading}
                      onChange={this.searchLogs}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className={classes.capital}>
                    Driver: {selectedDriver.firstName} {selectedDriver.lastName}
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className={classes.capital}>
                    {isCoDrivers ? (
                      <>
                        {`Co-driver: `}
                        {coDrivers.map((dr) => {
                          return (
                            <span
                              key={dr._id}
                            >{` ${dr.firstName} ${dr.lastName} `}</span>
                          );
                        })}
                      </>
                    ) : null}
                    <span style={{ float: "right" }}>
                      <input
                        onClick={() => this.setState({ isAll: !isAll })}
                        type="checkbox"
                        name="isAll"
                      />
                      <label htmlFor="ALL"> ALL</label>
                    </span>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleTabChange}
                aria-label="Days"
              >
                <Tab label={`ALL`} />
                {this.state.multiLogs &&
                  this.state.multiLogs.map((item, idx) => {
                    const evtTime =
                      item[0].eventTime ||
                      item[1].eventTime ||
                      item[2].eventTime;

                    return (
                      <Tab
                        key={`id-${idx}`}
                        label={`${this.props.formatUsDate(evtTime)}`}
                      />
                    );
                  })}
              </Tabs>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {loading ? (
                <EmptyLogGrid containerId="dutyStatusLogChart" />
              ) : (
                <>
                  {this.state.multiLogs &&
                    this.state.multiLogs.map((item, idx) => {
                      const evtTime =
                        item[0].eventTime ||
                        item[1].eventTime ||
                        item[2].eventTime;

                      if (tabValue === 0) {
                      } else if (tabValue === 1) {
                        if (idx !== 0) {
                          return null;
                        }
                      } else if (tabValue === 2) {
                        if (idx !== 1) {
                          return null;
                        }
                      } else if (tabValue === 3) {
                        if (idx !== 2) {
                          return null;
                        }
                      } else if (tabValue === 4) {
                        if (idx !== 3) {
                          return null;
                        }
                      } else if (tabValue === 5) {
                        if (idx !== 4) {
                          return null;
                        }
                      } else if (tabValue === 6) {
                        if (idx !== 5) {
                          return null;
                        }
                      } else if (tabValue === 7) {
                        if (idx !== 6) {
                          return null;
                        }
                      } else if (tabValue === 8) {
                        if (idx !== 7) {
                          return null;
                        }
                      } else if (tabValue === 9) {
                        if (idx !== 8) {
                          return null;
                        }
                      } else if (tabValue === 10) {
                        if (idx !== 9) {
                          return null;
                        }
                      }
                      return (
                        <div key={`id-${idx}`}>
                          <div>{`${this.props.formatDate(evtTime)}`}</div>
                          <LogGrid
                            timezone={timezone}
                            dailyDiary={dailyDiary}
                            driverLogs={item}
                            containerId={`dutyStatusLogChartLog-${idx}`}
                            formatTime={this.props.formatTime}
                            isAllLog={this.state.isAllLog}
                          />
                        </div>
                      );
                    })}
                </>
              )}
            </Grid>

            {loading ? (
              <Grid item xs={12} md={12} lg={12}>
                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <CircularProgress />
                </div>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <TableContainer
                    id="reviewTable"
                    className={classes.container}
                    style={{
                      maxHeight: this.state.tableMaxHgt,
                      overflow: "scroll",
                    }}
                  >
                    <Table
                      size="small"
                      onMouseLeave={() => this.handleMouseLeave()}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          {isCoDrivers && <TableCell>Driver</TableCell>}
                          {isAll && <TableCell>Vehicle</TableCell>}
                          <TableCell>Time</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Location</TableCell>
                          {isAll && <TableCell>State</TableCell>}
                          <TableCell>Odometer</TableCell>
                          <TableCell>EngineHr</TableCell>
                          <TableCell>Seq</TableCell>
                          <TableCell>Origin</TableCell>
                          <TableCell>Latitude</TableCell>
                          <TableCell>Longitude</TableCell>

                          {isAll && (
                            <>
                              <TableCell>Status</TableCell>
                              <TableCell>Sub</TableCell>
                              <TableCell>Origin</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Notes</TableCell>
                            </>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(allDayEvents) &&
                          allDayEvents.map((item, i) => {
                            if (item.isLast) {
                              return null;
                            }

                            return (
                              <ReduxTableRow
                                key={item._id}
                                item={item}
                                handleRowClick={this.handleRowClick}
                                index={item._id}
                                classes={this.props.classes}
                                onItemEdit={this.onItemEdit}
                                timezone={this.props.timezone}
                                formatDateTimeUtc={this.props.formatDateTimeUtc}
                                formatTime={this.props.formatTime}
                                formatTimeSec={this.props.formatTimeSec}
                                formatRawDate={this.props.formatRawDate}
                                isAll={isAll}
                                isCoDrivers={isCoDrivers}
                                editDisabled={editDisabled}
                              />
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
            {this.state.isEdit && (
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ textAlign: "center", padding: 15 }}
              >
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={saving || !this.state.isEdit}
                  style={{ minWidth: 150, marginTop: 15 }}
                  onClick={() => this.onSave()}
                >
                  {saving ? "Saving..." : "SAVE"}
                </Button>
                <br />
                <Button
                  size="small"
                  variant="link"
                  style={{ marginTop: 5 }}
                  onClick={() => this.reload()}
                >
                  {"RESET"}
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const adminKey = _get(state, "authData.profile.adminId");
  let showAllLogs = _get(
    state,
    "authData.profile.accountProperties.settings.showAllLogs",
    false
  );
  let isDebug = _get(
    state,
    "authData.profile.accountProperties.settings.debug",
    true
  );
  if (adminKey && isDebug) {
    showAllLogs = true;
  }
  return {
    logBusy: _get(state, "logData.logBusy", false),
    adminAccess: _get(state, "authData.adminAccess", false),
    adminScope: _get(state, "authData.profile.scopes"),
    driverCalcus: _get(state, "logData.driverCalcus", []),
    selectedLog: _get(state, "logData.selectedLog", {}),
    driverBusy: _get(state, "driverData.driverBusy", false),
    selectedDriver: _get(state, "driverData.selectedDriver", {}),
    defaultTimezone: _get(state, "appData.defaultTimezone"),
    timezone: _get(state, "appData.timezone"),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    accountId: _get(state, "authData.accountId"),
    adminKey: _get(state, "authData.profile.adminId"),
    profile: _get(state, "authData.profile.roles"),
    showAllLogs,
    isDebug: !!adminKey,
    isQa: _get(state, "appData.isQa"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTimeZone,
      getDriverById,
      getDriverDaily,
      getDriverLogs,
      formatTime,
      formatDateTime,
      getDailyLogsDays,
      getEditRequest,
      getAllCalcus,
      updateDriverDaily,
      updateAdminLogs,
      formatDateTimeUtc,
      formatDateTimeToUtc,
      getDailyLogsDayRange,
      getDaysCount,
      formatTimeSec,
      formatRawDate,
      formatUsDate,
      formatDate,
      getFmcsaHistoryCount,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DailyStatusDetail)
);
