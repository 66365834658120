import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BlockIcon from "@mui/icons-material/Block";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import LongMenu from "../../components/Menu";
import ReassignDriving from "../ReassignDriving";
import ConfirmActionModal from "../ConfirmActionModal";
import { RECORD_ORIGIN_ABV } from "../../constantsStatus";
import DutyStatus from "../../components/DutyStatusCir";
import ConfirmDialog from "../../components/ConfirmDialog";
import { validateOdometer, validateLocation } from "./helpers";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.dutyPage;

function pF(value) {
  return parseFloat(value) ? parseFloat(value) : 0;
}

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableHeaderCell: {
    textAlign: "left",
    padding: 10,
  },
  tableRowCell: {
    fontSize: 12,
    padding: 10,
    minWidth: 55,
  },
  tableRowCellSm: {
    fontSize: 12,
    padding: 10,
    minWidth: 75,
  },
  tableRowCellMd: {
    fontSize: 12,
    padding: 10,
    minWidth: 100,
  },
  tableRowCellLg: {
    fontSize: 12,
    padding: 10,
    minWidth: 125,
  },
  tableRowCellXl: {
    fontSize: 12,
    padding: 10,
    minWidth: 175,
  },
  cycleReset: {
    // textAlign: "left",
    // marginLeft: 55,
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textInactive: {
    textDecoration: "line-through",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  errorText: {
    color: "red",
  },
});

class ReduxTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  render() {
    const {
      classes,
      item = {},
      isAllLog = false,
      isCoDriver = false,
      isDebug = false,
      timezone,
      prevLog,
      prevLog2,
      isAdminDs,
      isAdminDsMul,
      isFmcsaSend,
      isViewOnly,
      isSmartelds,
      index,
    } = this.props;

    const eventTypeItems = isAllLog
      ? [
          "CHANGE_DUTY_STATUS",
          "INTERMEDIATE_LOG",
          "YARD_MOVES",
          "PERSONAL_USE",
          "CERTIFICATION",
          "LOGIN_LOGOUT",
          "POWERUP_SHUTDOWN",
          "MALFUNCTION_DIAGNOSTIC",
        ]
      : [
          "CHANGE_DUTY_STATUS",
          "YARD_MOVES",
          "PERSONAL_USE",
          // "CERTIFICATION",
          // "POWERUP_SHUTDOWN"
        ];

    const recordStatusItems = isAllLog
      ? [
          "ACTIVE",
          "INACTIVE_CHANGED",
          "INACTIVE_DEACTIVATE",
          "INACTIVE_CHANGE_REQUEST",
          "INACTIVE_CHANGE_REJECTED",
        ]
      : ["ACTIVE"];
    // : ["ACTIVE", "INACTIVE_CHANGE_REQUEST", "INACTIVE_CHANGE_REJECTED"];

    const tableClass = "";

    const isAllowed =
      recordStatusItems.includes(item.recordStatus) &&
      eventTypeItems.includes(item.eventType);

    if (!isAllowed || item.isLast) {
      return null;
    }

    let isBlocked = item.locked;
    let isMenu = item.recordStatus !== "INACTIVE_CHANGED";
    let blockText = "Waiting for driver to accept or reject.";
    let menuItems = [];
    let hide = false;

    const noChangeItems = [
      "LOGIN_LOGOUT",
      "POWERUP_SHUTDOWN",
      "CERTIFICATION",
      "INTERMEDIATE_LOG",
      "MALFUNCTION_DIAGNOSTIC",
    ];
    const noChangeStatusItems = [
      "INACTIVE_CHANGED",
      "INACTIVE_DEACTIVATE",
      "INACTIVE_CHANGE_REJECTED",
    ];
    const reAssignEventCode = ["DRIVING", "ONDUTY_ND"];

    const evtTime = item.eventTimeOrg || item.eventTime;

    const eventTime = this.props.formatTime(evtTime, timezone);
    const eventDate = this.props.formatRawDate(evtTime, timezone);
    const vehicle = item.vehicle || {};

    return (
      <TableRow hover className={`${classes.table} ${tableClass}`}>
        <TableCell className={`${classes.tableRowCellLg}`}>
          <strong>{eventTime}</strong>
          {index === 0 ? <div>{eventDate}</div> : null}
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          <DutyStatus
            status={item}
            tableClass={tableClass}
            eventType={item.eventType}
            eventCode={item.eventCode}
            annotation={item.annotation}
            eventSubType={item.eventSubType}
          />
        </TableCell>
        <TableCell className={`${classes.tableRowCellXl}`}>
          <div>{item.location}</div>
        </TableCell>

        <TableCell className={`${classes.tableRowCell}`}>
          {item.odometer && <div>{pF(item.odometer).toFixed()}</div>}
        </TableCell>

        <TableCell className={`${classes.tableRowCellLg}`}>
          {item.engineHours && <div>{item.engineHours}</div>}
        </TableCell>
      </TableRow>
    );
  }
}

export class CurrentStatusDetailTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // updateSelectedLogs
      allChecked: false,
      driverLogs: props.driverLogs,
      isConfirmCalcusOpen: false,
    };
  }

  componentDidMount() {}

  handleReassignDrivingModal = (e, rDOpen) => {
    this.setState({ rDOpen });
  };

  onDelete = async () => {
    this.setState({ deleting: true });
    const selected = this.state.selected;

    await this.props.deleteAdminLogs(selected);
    await this.props.searchLogStatus();

    this.setState({
      isConfirmOpen: false,
      deleting: false,
      selected: null,
    });
  };

  onCalcusDelete = async () => {
    this.setState({ deleting: true });
    const selected = this.state.selected;

    await this.props.deleteAdminCalcus(selected);
    await this.props.searchLogStatus();

    this.setState({
      isConfirmCalcusOpen: false,
      deleting: false,
      selected: null,
    });
  };

  isCoDriver = () => {
    const dailyDiary = this.props.dailyDiary || [];

    let isExist = false;

    for (let i = 0; i < dailyDiary.length; i++) {
      const coD = dailyDiary[i].coDriver || [];

      if (coD && Array.isArray(coD) && coD.length > 0) {
        isExist = true;
        break;
      }
    }

    return isExist;
  };

  handleCheckAll = (event) => {
    let _driverLogs = [];

    if (event.target.checked) {
      _driverLogs = this.state.driverLogs.map((item) => {
        item.isSelected = true;
        return item;
      });
    } else {
      _driverLogs = this.state.driverLogs.map((item) => {
        item.isSelected = false;
        return item;
      });
    }
    this.setState({
      allChecked: event.target.checked,
      driverLogs: _driverLogs,
    });
    this.props.updateSelectedLogs(_driverLogs);
  };

  handleCheckChange = (event, selected) => {
    let _driverLogs = this.state.driverLogs.map((item) => {
      if (selected.id === item.id) {
        item.isSelected = event.target.checked;
      }
      return item;
    });

    this.setState({ driverLogs: _driverLogs });
    this.props.updateSelectedLogs(_driverLogs);
  };

  render() {
    const {
      classes,
      dailyDiary,
      cycleStart,
      isDebug,
      adminKey,
      isAdminDs,
      isAdminDsMul,
      isAllLog,
      isUnity,
      isFmcsaSend,
      isViewOnly,
    } = this.props;
    const driverLogs = this.state.driverLogs;
    const allChecked = this.state.allChecked;
    const isConfirmApprovalOpen = this.state.isConfirmApprovalOpen;
    const {
      rDOpen,
      isConfirmOpen,
      isConfirmCalcusOpen,
      selected,
      deleting,
    } = this.state;
    const isCoDriver = this.isCoDriver();
    const role = _get(this, "props.profile[0]", "");
    const timezone = _get(this, "props.dailyDiary[0].timezone");

    return (
      <div>
        {rDOpen && (
          <ReassignDriving
            open={rDOpen}
            dailyDiary={dailyDiary}
            editType={"REASSIGN"}
            handleClose={() => {
              this.setState({ rDOpen: false });
              this.props.searchLogs(this.props.selectedDate);
            }}
          />
        )}
        {isConfirmOpen && (
          <ConfirmActionModal
            open={isConfirmOpen}
            selected={selected}
            loading={deleting}
            handleClose={() =>
              this.setState({
                isConfirmOpen: false,
                deleting: false,
                selected: null,
              })
            }
            handleConfirm={() => {
              this.setState({
                isConfirmApprovalOpen: true,
              });
            }}
            label={"Deactivate"}
            message={
              "Are you sure, you want to deactivate selected duty status"
            }
            driverConfirm={true}
            btnLabel={"DeActivate"}
          />
        )}
        {isConfirmApprovalOpen && (
          <ConfirmDialog
            open={isConfirmApprovalOpen}
            label={"Test mode: Driver action required"}
            agreeLabel={"Accept"}
            disAgreeLabel={"Reject"}
            text={"Please confirm with driver to accept or reject the request."}
            onClose={(isTrue) => {
              if (isTrue) {
                this.onDelete();
              }
              this.setState({
                isConfirmApprovalOpen: false,
                isConfirmOpen: false,
                deleting: false,
                selected: null,
              });
            }}
          />
        )}

        {isConfirmCalcusOpen && (
          <ConfirmActionModal
            open={isConfirmCalcusOpen}
            selected={selected}
            loading={deleting}
            handleClose={() =>
              this.setState({
                isConfirmCalcusOpen: false,
                deleting: false,
                selected: null,
              })
            }
            handleConfirm={this.onCalcusDelete}
            label={"Delete shift or cycle"}
            message={"Are you sure, you want to delete"}
          />
        )}
        <Paper className={classes.root}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.time}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.status}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.Location}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {`${LABELS.odometer}(Mi)`}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {"EngineHour"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(driverLogs) &&
                driverLogs.map((item, i) => {
                  if (item.isLast) {
                    return null;
                  }
                  let prevLog = driverLogs[i - 1];
                  let prevLog2 = driverLogs[i - 2];

                  if (i === 0) {
                    prevLog = item;
                    prevLog2 = item;
                  }
                  if (i === 1) {
                    prevLog2 = driverLogs[i - 1];
                  }
                  if (!item.cid) {
                    if (item.shiftReset || item.cycleResetTimestamp) {
                    } else if (!item.isStartDay) {
                      item = driverLogs[i - 1];
                    }
                  }
                  if (!prevLog.cid) {
                    prevLog = driverLogs[i - 2];
                  }
                  if (!prevLog2.cid) {
                    prevLog2 = driverLogs[i - 3];
                  }

                  return (
                    <ReduxTableRow
                      item={item}
                      prevLog={prevLog}
                      prevLog2={prevLog}
                      key={i}
                      index={i}
                      classes={classes}
                      cycleStart={cycleStart}
                      isCoDriver={isCoDriver}
                      isDebug={isDebug}
                      isAllLog={this.props.isAllLog}
                      isAdminDs={isAdminDs}
                      isAdminDsMul={isAdminDsMul}
                      timezone={timezone}
                      isFmcsaSend={isFmcsaSend}
                      formatTime={this.props.formatTime}
                      searchLogStatus={this.props.searchLogStatus}
                      setSelectedLog={this.props.setSelectedLog}
                      handleReassignDrivingModal={
                        this.handleReassignDrivingModal
                      }
                      formatRawDate={this.props.formatRawDate}
                      deleteAdminLogs={(selected) => {
                        this.setState({
                          selected,
                          isConfirmOpen: true,
                        });
                      }}
                      deleteCalcus={(selected) => {
                        this.setState({
                          selected,
                          isConfirmCalcusOpen: true,
                        });
                      }}
                      handleLogEdit={this.props.handleLogEdit}
                      adminScope={this.props.adminScope}
                      role={role}
                      isQa={this.props.isQa}
                      startOfDay={this.props.startOfDay}
                      adminKey={adminKey}
                      isUnity={isUnity}
                      handleCheckChange={this.handleCheckChange}
                      formatTimeSec={this.props.formatTimeSec}
                      isViewOnly={isViewOnly}
                      isSmartelds={this.props.isSmartelds}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

CurrentStatusDetailTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CurrentStatusDetailTable);
