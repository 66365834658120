/* eslint-disable jsx-a11y/alt-text */
import _get from "lodash/get";
import * as React from "react";
import { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ChatInput from "./ChatInput";
import CircularProgress from "@mui/material/CircularProgress";
import ImageIcon from "@mui/icons-material/Image";
import { sorting } from "../../utils/sortingHelpers";
import { Buffer } from "buffer";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Dialog, DialogContent, Tooltip } from "@mui/material";

function RenderItem(props) {
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [imageName, setImageName] = useState();
  const [rotation, setRotation] = useState(0);
  const [open, setOpen] = useState(false);

  const item = props.item || {};
  let message = item.message || "";
  const created = item.created;
  const messageType = item.type;
  // console.log('messageType === ', messageType);
  const isImage = messageType === "image" || messageType === "document";
  const isReply = item.status === "REPLY";
  const timeFormated = props.formatTimeSec(created);
  const dateFormated = props.formatUsDate(created);
  let datetime = `${dateFormated} @ ${timeFormated}`;

  if (props.isToday(created)) {
    datetime = timeFormated;
  }
  if (props.isYesterday(created)) {
    datetime = `Yesterday @ ${timeFormated}`;
  }

  if (item.type === "request_welcome") {
    message = `Joined chat at ${datetime}`;
    datetime = "";
  }

  async function onDownload() {
    const a = document.createElement("a");
    a.href = imageData;
    a.download = imageName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function getFileName(response) {
    let fileName = _get(response, "headers.filename");

    if (!fileName) {
      const contentType = _get(response, "headers[content-type]");
      const ext = contentType.split("/")[1];
      return `download.${ext}`;
    } else {
      return fileName;
    }
  }

  async function loadImage() {
    try {
      setLoading(true);
      const response = await props.getMedia({ id: item._id });

      if (response && response.data) {
        const contentType = _get(response, "headers[content-type]");
        const fileName = getFileName(response);
        const base64 = Buffer.from(response.data, "binary").toString("base64");
        const imageUrl = `data:${contentType};base64,${base64}`;
        setImageName(fileName);
        setImageData(imageUrl);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  const handleRotate = () => {
    setRotation((prev) => (prev + 90) % 360);
  }

  const handleImageClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ListItem
      alignItems="flex-start"
      onClick={() => props.handleClick(item)}
      style={{
        cursor: "pointer",
        marginRight: isReply ? 0 : 150,
        marginLeft: isReply ? 150 : 0,
        width: "calc(100% - 160px)",
        backgroundColor: isReply ? "#009be533" : "#f0f0f0",
        padding: 10,
        borderRadius: 10,
        marginTop: 5,
      }}
    >
      {imageData ? (
        imageName.includes(".pdf") ? (
          "PDF FILE"
        ) : (
          <>
            <div>
              <img
                width={500}
                style={{
                  height: "100%",
                  objectFit: "contain",
                  transform: `rotate(${rotation}deg)`,
                  transition: "transform 0.3s ease",
                  cursor: "pointer",
                }}
                src={imageData}
                alt="Preview"
                onClick={handleImageClick}
              />
            </div>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              maxWidth="lg"
              PaperProps={{
                style: {
                  width: "90vw",
                  height: "90vh",
                  margin: "20px",
                  backgroundColor: "#202020",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                },
              }}
            >
              <DialogContent
                 style={{
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                  overflow: "auto",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <img
                  src={imageData}
                  alt={imageName}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                    transform: `rotate(${rotation}deg)`,
                    transition: "transform 0.3s ease",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    display: "flex",
                    gap: "10px",
                    background: "rgba(255, 255, 255, 0.1)",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                >
                  <Tooltip title="Rotate Image">
                    <RotateRightIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRotate();
                      }}
                      style={{ cursor: "pointer", color: "white" }}
                    />
                  </Tooltip>
                  <Tooltip title="Download Image">
                    <DownloadForOfflineIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        onDownload();
                      }}
                      style={{ cursor: "pointer", color: "white" }}
                    />
                  </Tooltip>
                </div>
              </DialogContent>
            </Dialog>
          </>
        )
      ) : null}
      <ListItemText
        primary={
          <Typography>
            {isImage && !imageData ? (
              <>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <ImageIcon onClick={loadImage} />
                )}
              </>
            ) : (
              message
            )}
            {imageData && (
              <span style={{ textAlign: "right", display: "block" }}>
                <Tooltip title="Rotate Image">
                  <RotateRightIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRotate();
                    }}
                    className="cursor-pointer hover:text-blue-600"
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
                <DownloadForOfflineIcon onClick={onDownload} />
              </span>
            )}
          </Typography>
        }
        secondary={
          <React.Fragment>
            <Typography
              sx={{ display: "block" }}
              style={{ float: "right" }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {datetime}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
}

export default function ChatMessage(props) {
  const selected = props.selected || {};
  const name = selected.from && selected.from.name;
  const items = props.items || [];
  const lastItem = items[items.length - 1] || {};

  useEffect(() => {
    scrollToLast();
  }, [lastItem._id]);

  function scrollToLast() {
    try {
      const element = document.getElementById("lastOne");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (!name) {
    return null;
  }

  return (
    <>
      <h3 style={{ marginBottom: 5 }}>
        <ListItemAvatar style={{ display: "flex" }}>
          <Avatar alt={name} src="/static/images/avatar/1.jpg" />
          <span style={{ paddingTop: 6, marginLeft: 10 }}>{name}</span>
        </ListItemAvatar>
      </h3>
      {props.loadingMsg && (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          maxHeight: 550,
          minHeight: 525,
          overflow: "scroll",
        }}
      >
        {sorting(items, "timestamp").map((item) => (
          <RenderItem
            key={item._id}
            item={item}
            isToday={props.isToday}
            selected={props.selected}
            formatTime={props.formatTime}
            formatTimeSec={props.formatTimeSec}
            isYesterday={props.isYesterday}
            handleClick={props.handleClick}
            formatUsDate={props.formatUsDate}
            getMedia={props.getMedia}
          />
        ))}
        <button style={{ visibility: "hidden" }} id="lastOne"></button>
      </List>
      <ChatInput
        chat={props.selected}
        handleChatReply={props.handleChatReply}
      />
    </>
  );
}
