
import React, { createContext, useContext, useMemo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { gMapApiKey } from "../utils/constants"
import { GOOGLE_MAPS_CONFIG } from './googleMaps';

const GoogleMapsContext = createContext(null);

export const GoogleMapsProvider = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: gMapApiKey,
    libraries: GOOGLE_MAPS_CONFIG.libraries,
    id: 'google-map-script',
  });

  const value = useMemo(() => ({
    isLoaded,
    loadError,
    config: GOOGLE_MAPS_CONFIG,
  }), [isLoaded, loadError]);

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return (
    <GoogleMapsContext.Provider value={value}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => {
  const context = useContext(GoogleMapsContext);
  if (context === null) {
    throw new Error('useGoogleMaps must be used within a GoogleMapsProvider');
  }
  return context;
};