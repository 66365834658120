import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function MileTable(props) {
  const miles = props.miles || [];
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Mile table">
        <TableHead>
          <TableRow>
            <TableCell>State</TableCell>
            <TableCell>Miles</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {miles.map((mile) => {
            if (mile.total === 0) {
              return null;
            }
            return (
              <TableRow key={mile.state}>
                <TableCell>{mile.state}</TableCell>
                <TableCell>{`${mile.total} Mile(s)`}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
