import _get from "lodash/get";
import React from "react";
import Grid from "@mui/material/Grid";
import { Field } from "formik";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const renderCheckbox = ({
  field,
  label,
  color,
  labelPlacement,
  classes,
  onChange,
  form: { touched, errors, values, handleChange, handleBlur },
}) => {
  let value = _get(values, `${field.name}`);
  return (
    <div>
      <FormControlLabel
        labelPlacement={labelPlacement}
        className={classes}
        control={
          <Checkbox
            id={field.name}
            color={color}

          />
        }
        label={label}
      />
    </div>
  );
};

export default function ReduxCheckBox({
  onClick,
  xs = 12,
  sm = 12,
  md = 4,
  name,
  label,
  color = "primary",
  type = "text",
  placeholder,
  loading = false,
  labelPlacement = "end",
  classes,
  paddingTop = 15,
  onChange = () => {},
}) {
  const _label = label ? label : name;
  return (
    <Grid item xs={xs} sm={sm} md={md} style={{ paddingTop }}>
      {/*<Field
        color={color}
        name={name}
        component={renderCheckbox}
        label={_label}
        labelPlacement={labelPlacement}
        classes={classes}
        onChange={onChange}
      />*/}
      <Field type="checkbox" name={name} />
      {_label}
    </Grid>
  );
}
