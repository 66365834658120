import axios from "axios";
import _get from "lodash/get";

export function getDueInvoices() {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.CO_URL");
    try {
      let config = {
        method: "get",
        url: "https://co.osritapi.com/billing-dues-sm",
      };

      const response = await axios.request(config);
      let _response = _get(response, "data.data", []);
      return _response;
    } catch (error) {
      return null;
    }
  };
}
